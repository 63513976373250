*, *::before, *::after {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('../../../resource/fonts/Rubik-Regular.ttf') format('truetype'),
    url('../../../resource/fonts/rubik-regular-webfont.woff') format('woff'),
    url('../../../resource/fonts/rubik-regular-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('../../../resource/fonts/Rubik-Medium.ttf') format('truetype'),
    url('../../../resource/fonts/rubik-medium-webfont.woff') format('woff'),
    url('../../../resource/fonts/rubik-medium-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('../../../resource/fonts/Rubik-Italic.ttf') format('truetype'),
    url('../../../resource/fonts/rubik-italic-webfont.woff') format('woff'),
    url('../../../resource/fonts/rubik-italic-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('../../../resource/fonts/rubik-mediumitalic-webfont.ttf') format('truetype'),
    url('../../../resource/fonts/rubik-mediumitalic-webfont.woff') format('woff'),
    url('../../../resource/fonts/rubik-mediumitalic-webfont.woff2') format('woff2');
}

html {
    overflow-y: auto;
}

body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: $f-dark;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
}

.content--xs {
    width: $content-width--xs;
    max-width: 100%;
    margin: 0 auto;
}

.content--s {
    width: $content-width--s;
    max-width: 100%;
    margin: 0 auto;
}

.content--m {
    width: $content-width--m;
    max-width: 100%;
    margin: 0 auto;

    table {
        width: 100%;
    }
}

.content--l {
    width: $content-width--l;
    max-width: 100%;
    margin: 0 auto;
}

.content--not-centered {
    margin: 0;
    padding: 30px 15px 0 15px;

    @include from($tablet) {
        padding-left: 30px;
    }

    &.no-padding--top {
        padding-top: 0;
    }
}

.content--to-left {
    margin: 0;
}

.side-spacing {
    padding: 0 15px;
}