//colors
$c-primary: #183970;
$c-primary--light: lighten($c-primary, 50);
$c-primary--dark: darken($c-primary, 7%);
$c-primary--darkest: darken($c-primary--dark, 5%);

$c-secondary--light: #eef1f5;
$c-secondary--medium: #d9dfe9;
$c-secondary--dark: #7a8fb5;

$c-cta: #347af6;
$c-cta-gradient: #1659ce;
$c-error: #cb4125;
$c-error-gradient: #af361d;
$c-success: #1b8574;

// fonts
$f-light: #fff;
$f-medium: #848b98;
$f-dark: #0a1933;
$f-light-blue: #75c9fd;

// background
$bg-light: #fff;
$bg-dark: $c-primary;
$bg-gray: $c-secondary--light;
$bg-light-blue: #f3f7ff;