.accounts-options{
  font-weight: 500;
  border-bottom: 1px solid $c-secondary--light;

  .row__left{
    display: flex;
    align-items: center;

    p{
      line-height: 1.2;
    }
  }
}

.accounts-options__slot-number{
  padding: 10px;
  margin-right: 10px;
  font-size: $size-18;
  border-radius: $border-radius;
  background-color: $c-secondary--light;
}

.accounts-list{

}

.account-item{
  display: block;
  position: relative;
  border-bottom: 1px solid $c-secondary--light;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  &:hover, &:focus{
    border: 1px solid $c-cta;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    cursor: pointer;
  }
}

.account__content{
  display: flex;
  padding: 15px 60px 15px 0;
}

.account__status-icon{
  width: 40px;
  height: 40px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 50%;
}

.account-item__info{
  flex-grow: 1;
  overflow: hidden;
}

.account-item__status-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-item__activation-code{
  font-size: $size-12;
}

.account__status-icon--pending,
.account__status-icon--suspended{
  background-color: $c-secondary--light;
}

.account__status-icon--admin,
.account__status-icon--user{
  background-color: $c-primary;
}

.account-item__dropdown{
  position: absolute;
  right: 5px;
  top: 15px;
}

