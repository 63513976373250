.options-tabs{
    margin-bottom: 40px;
}

.options-table{
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
}

.options-title{
    margin-bottom: 15px;
}

//regulations

.regulations{
    margin-bottom: 50px;

    .title-with-divider{
        margin-bottom: 15px;
    }
}

//change password

.change-passowrd-form{
    button[type="submit"]{
        margin: 20px 0;
    }
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 30px;
}
  
.iframe-container Iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
}