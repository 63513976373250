.notification-status-wrapper {
    width: 35px;
    height: 35px;
    padding: 7px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid $c-secondary--dark;

    &--pending {
        background-color: $c-cta;
    }
}

.notification-status {
    width: 100%;
    height: 100%;
    background: url("../../../../resource/img/icons/icon_mail.svg") center/contain no-repeat;
}

.notification-status--sent {
    background: url("../../../../resource/img/icons/icon_mail.svg") center/contain no-repeat;
}

.notification-status--error {
    background: url("../../../../resource/img/icons/icon_alert_triangle.svg") center/contain no-repeat;
}

.notification-status--canceled {
    background: url("../../../../resource/img/icons/icon_message_canceled.svg") center/contain no-repeat;
}

.notification-status--pending {
    background: url("../../../../resource/img/icons/icon_clock_white.svg") center/contain no-repeat;
}

.notification-status-with-text {
    display: flex;
    align-items: center;
}

.notification-status-with-text--error {
    color: $c-error;
    & > .notification-status-wrapper {
        border-color: $c-error;
    }
}

.notifications--pending {
    .notification__date {
        font-weight: 500;
    }

    .notification-status-wrapper {
        background-color: $c-cta;
    }

    .notification-status {
        background: url("../../../../resource/img/icons/icon_clock_white.svg") center/contain no-repeat;
    }

    tr:last-of-type {
        border-bottom: 2px solid $c-secondary--dark;
    }
}

.message-history{

    .row--options{
        @media screen and (max-width: 1250px){
            height: auto;
            flex-wrap: wrap;

            button, input, .select-wrapper{
                margin: 5px 0;
            }
        }
    }

    .options--group{
        display: flex;

        @media screen and (max-width: 600px){
            flex-wrap: wrap;
        }
    }
}
