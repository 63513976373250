$size-11: 0.8em;
$size-12: 0.86em;
$size-13: 0.93em;
$size-14: 1em;
$size-15: 1.08em;
$size-16: 1.15em;
$size-17: 1.22em;
$size-18: 1.3em;
$size-19: 1.36em;
$size-19: 1.45em;
$size-22: 1.58em;
$size-24: 1.70em;
$size-26: 1.86em;

$f-size--s: $size-12;
$f-size--m: $size-14;
$f-size--l: $size-18;
$f-size--xl: $size-26;


