.custom-input {
    height: 22px;
    width: 22px;
    margin-right: 12px;
}

.custom-input__input {
    display: none;

    & + .custom-input__new-input {
        display: block;
        position: relative;
        cursor: pointer;

        &::before, &::after {
            border-radius: 50%;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
        }

        &::before {
            border: 2px solid $c-secondary--dark;
            background: #fff;
        }

        &::after {
            background-color: $c-cta;
            background-image: url("../../../resource/img/icons/icon_check.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
    }

    &:not(:checked) + .custom-input__new-input::after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    &:checked + .custom-input__new-input::after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}