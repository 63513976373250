.text-box {
    margin-bottom: 10px;

    .tox-tinymce {
        border: 1px solid $c-secondary--dark;
        border-radius: 4px;
    }

    .tox .tox-edit-area {
        border: 0;
    }

    .tox .tox-toolbar {
        order: 2;
        border-top: 1px solid $c-secondary--dark !important;
    }
}

.text-box__error {
    @extend .text-box;

    margin-bottom: 0;

    .tox-tinymce {
        border: 1px solid $c-error;
    }

    .tox .tox-sidebar-wrap {
        border: 0;
    }

    .tox .tox-toolbar {
        border-top: 0 !important;
    }
}

.text-box .tox-toolbar__group {
    & button {
        cursor: pointer;
    }

    & button .tox-icon svg {
        display: none;
    }

    & button .tox-icon {
        display: block;
        height: 18px;
        width: 18px;
        position: relative;
        color: transparent; //bug fix (?) - "!not found!" message is shown without any reason
    }

    & button .tox-icon::before {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 0;
    }

    & button[title="Bold"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_bold.svg');
    }

    & button[title="Italic"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_italic.svg');
    }

    & button[title="Underline"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_underline.svg');
    }

    //TODO
    & button[title="Bullet list"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_number_list.svg');
    }

    & button[title="Numbered list"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_number_list.svg');
    }

    & button[title="Special character"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_special_character.svg');
    }

    & button[title="Insert/edit link"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_link.svg');
    }

    & button[title="Clear formatting"] .tox-icon::before {
        background-image: url('../../../resource/img/icons/tinymce/icon_T.svg');
    }
}

.text-box-button {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 140px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: unset;
    border-bottom-right-radius: 4px;
}
