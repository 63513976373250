//layout

.container.is-fluid {
    margin: 0 30px;
}

.media + .media{
    border: none;
}

//typography

.title {
    font-weight: 500;
    color: $c-primary;

    &:not(:last-child) {
        margin: 0;
    }
}

.title.is-1,
.subtitle.is-1 {
    font-size: 1.45em;
}

.title.is-2,
.subtitle.is-2 {
    font-size: 1.15em;
}

.title.is-3,
.subtitle.is-3 {
    font-size: 1em;
}

.title.is-4,
.subtitle.is-4 {
    font-size: 0.9em;
}

.title.is-5,
.subtitle.is-5 {
    font-size: 0.75em;
}

.title.is-6,
.subtitle.is-6 {
    font-size: 0.5em;
}

//colors

.title,
.subtitle,
.box {
    color: unset;
}

.navbar-item {
    color: $c-primary;
}

.navbar-link:not(.is-arrowless)::after {
    border-color: $c-secondary--light;
}

.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
    background-color: unset;
}

a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:hover,
.navbar-link.is-active {
    background-color: unset;
    color: unset;
}

//elemenets

.admin-navbar {
    .navbar-item,
    .navbar-item.has-dropdown {
        display: inline-flex;
        padding: 0 15px;
        align-items: center;
    }
}

.dropdown-menu{
    padding-top: 0;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.25);
    border-radius: 4px;
    top: 125%;
    z-index: 1000;
    width: max-content;
}

.dropdown-content{
    box-shadow: none;
}

.dropdown-item{
    width: 100%;
    padding: 8px 15px;

    &:hover,
    &:focus{
        background-color: $c-secondary--light;
    }
}

.field:not(:last-child){
    margin-bottom: 20px;
}

.progress.is-small{
    height: 2px;
}

.progress.is-primary:indeterminate{
    background-image: linear-gradient(to right, $c-cta 30%, $c-secondary--light 30%);
}

.progress:indeterminate{
    animation-duration: 2s;
    background-color: $c-secondary--light;
}

@include until($desktop){
    .title.is-1,
    .subtitle.is-1 {
        font-size: $size-16;
    }
}

