.main-container--signed-out {
    min-height: 100vh;
    display: flex;
    background-color: $bg-light;
}


.main-container--signed-out__left{
    max-width: 100vw;
    padding: 40px 40px 15px;
    flex-grow: 1;
}

.main-container--signed-out__right{
    display: none;
}

.signed-out-page{
    position: relative;
    min-height: 100%;
}

.signed-out-page__logo{
    position: absolute;
    top:  0;
    left: 0;
}

.signed-out-page__ue{
    position: absolute;
    top:  0;
    right: 0;
}

.signed-out-page__main-content {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 150px 0;

    input{
        margin-top: 5px;
    }
}

.footer-item {
    display: inline-block;
    margin: 15px 0;
    font-size: $f-size--s;
}

.footer-copy{
    padding-right: 30px;
    border-right: 1px solid $f-dark;
}

.footer-regulations{
    margin-left: 30px;

    li{
        display: inline-block;

        &:not(:last-child){
            margin-right: 30px;

        }
    }
}

.header--signed-out{
    margin-bottom: 30px;
}

.title--signed-out{
    font-size: $f-size--xl;
    font-weight: 500;
}

.go-back--signed-out{
    display: inline-flex;
    color: $c-primary;
    font-weight: 500;
    font-size: $f-size--s;
    svg{
        margin-right: 15px;
    }
}


//form

.form--signed-out {
    margin-bottom: 40px;

    .input-wrapper {
        margin: 30px 0 40px;
    }
}

.signUp__form,
.reset-pass__form {

    &.step-1 {
        .input-wrapper {
            margin: 5.6em 0 8em;
        }
    }

    &.step-2 {
        .input-wrapper {
            margin: 3.2em 0 3em;
        }
    }
}

.reset-pass__form {

    & > .subtitle {
        margin-top: 15px;
        color: $c-secondary--dark;
    }
}

// hints

.hint {
    display: block;
    margin: 2px 0 15px;
    font-size: 0.8em;
}

.hint--datepicker{
    bottom: -15px;
}

.hint--rejected {
    color: $c-error;
}

.hint--success {
    color: $c-success;
}

.c-modal__body{
    .hint{
        position: absolute;
    }
}

//error info

.info-wrapper {
    margin: -5rem auto 30px;
    padding: 15px 25px;
    border-radius: 0 0 4px 4px;
}

.info {
    position: relative;
    font-weight: 600;
    font-size: 0.8em;
    padding-left: 40px;
}

.info-wrapper--error {
    background-color: rgba($c-error, 0.08);
}

.info--error {
    color: $c-error;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        //background: url("../resource/img/icons/rejected.svg") center/cover no-repeat;
    }
}

.remind-password{
    margin-top: 20px;
    text-align: right;
    font-weight: 500;
    font-size: $f-size--s;

    a{
        color: $c-primary;
    }
}

.signed-out-page__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.form-message{
    padding: 15px;
    margin-bottom: 10px;
    border-radius: $border-radius;
}

.form-message__text{
    position: relative;
    padding-left: 30px;

    &:before{
        content: "";
        position: absolute;
        left: 0;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
    }
}

.form-message--error{
    background-color: rgba($c-error, .1);
    color: $c-error;

    .form-message__text{
        &:before{
            background-image: url("../../../../resource/img/icons/icon_alert.svg");
        }
    }
}

.form-message--confirmation{
    background-color: rgba($c-success, .1);
    color: $c-success;

    .form-message__text{
        &:before{
            background-image: url("../../../../resource/img/icons/icon_check_alt.svg");
        }
    }
}


.login-reminder{
    padding: 23px 50px;
    margin-bottom: 40px;
    overflow: hidden;
    border-radius: $border-radius;
    background-color: $c-secondary--light;
}

@media screen and (max-width: 500px){

    .signed-out-page__footer--links{
        display: flex;
        flex-direction: column-reverse;
        justify-items: center;
        align-items: center;
    }

    .footer-item{
        margin: 10px;
        padding: 0;
        border: none;
    }

}

@include mobile{
    .main-container--signed-out__left{
        padding: 15px;
    }

    .signed-out-page__main-content{
        padding-top: 100px;
        padding-bottom: 200px;
    }
}

@include from($desktop){
    .main-container--signed-out__left{
        width: auto;
    }

    .main-container--signed-out__right{
        display: block;
        width: 580px;
        min-height: 100vh;

        .signed-out-img{
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $c-secondary--light;

            img{
                position: absolute;
                max-width: 110%;
                height: auto;
                top:  50%;
                left: -40px;
                transform: translateY(-55%);
            }
        }
    }
}

@media screen and (max-width: 1630px){
    .signed-out-page__footer{
        flex-direction: column-reverse;
        align-items: center;
    }
}
