.status-box{
    margin-bottom: 50px;

    .status{
        margin-bottom: 10px;
        padding: 15px;
        border-radius: $border-radius;

        .is-underlined{
            cursor: pointer;
            margin-left: 5px;
        }

        svg{
            margin-right: 10px;
        }
    }

    .status--pending{
        color: $f-light;
        background-color: $c-cta;
    }

    .status--suspended{
        color: $c-error;
        background-color: rgba($c-error, .2);
    }

    .activation-code{
        display: flex;
        justify-content: center;
        padding: 15px;
        background-color: $c-secondary--light;

        .code{
            margin-top: 10px;
            padding: 5px 50px;
            background-color: #fff;
            font-weight: 500;
        }
    }

    .tooltip-trigger{
        height: 18px;
        margin-left: 15px;
    }
}

.tippy-tooltip.custom-theme {
    max-width: 300px;
    background-color: $c-primary;
    color: $f-light;
    text-align: left;
}


