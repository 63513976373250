.checkbox-list{

  label{
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 400;
    color: $f-dark;
  }

  .checkbox-label__name{
    margin-left: 20px;
  }
}

.incorrect-addresses-modal {
    table {
        max-width: unset;
        width: unset;
        overflow-y: auto;
        background-color: #f4f6f9;

        tr {
            height: 32px;
            border: none;
            display: table-row;
        }

        td {
            display: table-cell;
        }
    }

    .table-wrapper {
        overflow-y: auto;
        height: 45vh;
    }
}
