.payments-spacing{
    margin-bottom: 30px;
}

.payments{

    .options-wrapper--horizontal{
        max-width: 350px;
    }
}

.input--single-day{
    width: 66px;
    margin: 0 10px;
}

.payment-details__trigger{
    position: relative;
    padding-right: 25px;
    color: $c-primary;
    display: inline-block;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        width: 0.625em;
        height: 0.625em;
        margin-top: -0.4375em;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: center;
        transition: 300ms ease;
        pointer-events: none;

        border: 3px solid $c-primary;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
    }

    &.open{
        &:after{
            transform: rotate(135deg);
            top: 60%;
        }
    }
}

.payment-details__trigger-icon{
    margin-left: 8px;
}

.payments-details__content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 20px 0;

    label{
        font-weight: 400;
    }

    .input--single-day{
        margin: 0 20px 6px 0;
    }

    .column{
        display: flex;
        flex-direction: column;
    }
}

.payment-details__column{
    display: flex;
    flex-shrink: 0;
    flex-direction: column;

    &:not(:last-child){
        margin-right: 70px;
    }
}