.layout--message{
    min-height: 100vh;
    padding: 0 15px;
    background-color: $c-secondary--light;
}

.container--message {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding: 15vh 0;

    .logo{
        display: inline-block;
        margin: 0 auto 15px;
    }

    .content-wrapper{
        box-shadow: $box-shadow;
        background-color: $bg-light;
    }
}

.content--message{
    min-height: 275px;
    padding: calc(20px + 2vw);

    .owner{
        margin-bottom: 40px;
        font-weight: 500;

        img{
            margin-right: 13px;
            max-height: 38px;
        }
    }

    .message{
        margin-bottom: 30px;
        background-color: inherit;
    }
}

.text--message{
    font-size: $size-24;
    font-weight: 500;
    text-align: center;
}

