label,
.label,
.label:not(:last-child) {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
}

input,
textarea,
.rc-time-picker-input {
    padding: 10px 15px;
    height: 2.6rem;
    width: 100%;
    max-width: 100%;
    border-radius: $border-radius;
    border: 1px solid $c-secondary--dark;
    font-size: 1em;
    outline: none;

    &.rejected {
        border-color: $c-error;
    }

    &:focus, &:active {
        box-shadow: 0 0 0 3px rgba($c-cta, 0.25);
    }

    &::placeholder {
        color: $c-secondary--dark;
    }

    &:disabled {
        border: none;

        &:focus, &:active {
            box-shadow: none;
        }
    }
}

input:disabled,
textarea:disabled {
    background-color: $c-secondary--light;
}

input[type='checkbox'],
input[type='radio'] {
    height: auto;
    width: auto;
}

textarea {
    min-height: 85px;
    resize: vertical;
}

input[type="submit"] {
    border: none;
    cursor: pointer;
}

.submit {
    margin-top: 2.7em;

    .suggestion {
        margin-top: 1.6em;
        text-align: center;
    }

    .suggestion--double {
        display: flex;
        justify-content: space-between;
    }
}

.with-icon--left {
    position: relative;

    input {
        padding-left: 40px;
    }

    .input-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        max-height: 100%;
    }
}

.with-icon--right {
    position: relative;

    input {
        padding-right: 40px;
    }

    .input-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        max-height: 100%;
    }
}

.go-back {
    margin-top: 6.5em;
}

.field-separated--bottom {
    margin-bottom: 30px;

    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

//custom checkbox

.acceptance-label {
    display: inline-block;
    position: relative;
    min-height: 18px;
    padding-left: 18px;
    cursor: pointer;
    font-weight: 400;



    &:hover,
    &:focus {

        &:before {
            box-shadow: 0 0 0 4px $c-secondary--medium;
        }
    }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 2px solid $c-secondary--dark;
        border-radius: 3px;
        background-color: #fff;
        transition: 200ms ease-in-out;
    }

    &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 4px;
        border: 0;
        height: 6px;
        transform: rotate(-45deg);
        transition: transform .2s ease, height .2s ease, width .1s ease;
        width: 0;
    }

    a {
        color: $c-secondary--light;
        font-weight: 600;

        &:hover,
        &:active {
            color: $c-secondary--light;
            text-decoration: underline;
        }
    }

    &--disabled {
        color: $f-medium;
        cursor: auto;

        &:before {
            border: 2px solid $c-secondary--medium;
        }

        &:hover,
        &:focus {

            &:before {
                box-shadow: none;
            }
        }
    }

    &.label--disabled {
        cursor: unset;

        &:before {
            background-color: $c-secondary--medium;
            border-color: $c-secondary--medium;
        }

        &:hover,
        &:focus {
            &:before {
                box-shadow: none;
            }
        }
    }
}

.label--checked {
    &:before {
        background-color: $c-primary;
        border-color: $c-primary;
    }

    &:after {
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        width: 10px;
    }

    &.label--disabled {

        &:before {
            background-color: $c-secondary--medium;
            border-color: $c-secondary--medium;
        }

        &:hover,
        &:focus {
            &:before {
                box-shadow: none;
            }
        }
    }
}

.acceptance-checkbox {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.acceptance-text {
    display: block;
    margin-left: 10px;
}

.acceptance-secondary-text {
    color: $f-medium;
    display: block;
    margin-left: 10px;
}

// custom radio button

.options-wrapper--horizontal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.choice--radio {
    position: absolute;
    opacity: 0;
}

.choice-label {
    position: relative;
    display: inline-block;
    padding: 5px 10px 5px 30px;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 400;

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        height: 20px;
        min-width: 20px;
        box-sizing: border-box;
        margin-left: -28px;
        margin-right: 8px;
        transition: border-color .3s;
        background-color: $bg-light;
        border: 2px solid $c-secondary--dark;
        border-radius: 4px;
    }

    &:after {
        content: "";
        background: transparent;
        display: inline-block;
        position: absolute;
        transform-origin: top left;

        border: 0;
        box-sizing: border-box;
        height: 8px;
        left: 5px;
        top: 17px;
        transform: rotate(-45deg);
        transition: transform .5s ease-in, height .4s ease-in, width .2s ease-in;
        width: 0;
    }

    &:hover {
        &:before {
            border-color: $c-primary;
        }
    }
}

.choice-label--radio:before {
    border-radius: 50%;
}

.choice-label--radio:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 15px;
}

.checked--radio {
    &:before {
        border-color: $c-primary;
    }

    &:after {
        background-color: $c-primary;
    }
}

//

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none; /* Webkit */
        margin: 0;
    }

}

input[type='password'] {
    padding-right: 50px;
}

.show-password {
    position: absolute;
    height: 24px;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
}

.form-row--horizontal:not(:last-child) {
    margin-bottom: 30px;
}

.label-name {
    font-weight: 500;
}

.label-name--light {
    font-weight: 400;
}

.form--horizontal,
.form--vertical {

    label {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .less-spacing--bottom {
        margin-bottom: 10px;
    }

    .no-spacing--bottom {
        margin-bottom: 4px;
    }
}

.form--horizontal {

    label {
        display: flex;
        margin: 30px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }


    .label-name {
        min-width: 115px;
        margin-right: 30px;
        margin-top: 10px;
    }

    .right-section {
        margin-left: 145px;
    }
}

.form--vertical {

    label {
        display: block;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &:last-of-type.is-spaced-bottom--20 {
            margin-bottom: 20px;
        }
    }

    .label-name, legend {
        display: block;
        margin-bottom: 2px;
    }

    .columns .is-half {
        padding: 0 15px 0 0;

    }
}

.multiple-inputs-row {
    display: flex;

    .main-width-input {
        flex-grow: 1;
    }

    @include from($tablet) {
        .main-width-input--left {
            margin-right: 20px;
        }
    }

    @include until($tablet) {
        flex-direction: column;
    }
}

.range-input {
    label {
        display: inline-flex;
        align-items: center;
    }

    span {
        margin: 0 5px;
        font-weight: 400;
        color: $c-secondary--dark;
    }

    input {
        width: 80px;
    }
}



.input-size-xs {
    width: 80px;
}

.input-size-s {
    width: 100px;
}

.input-size-m {
    width: 180px;
}

.input-size-l {
    width: 200px;
}

.input-size-xl {
    width: 400px;
}

.input-size--date {
    width: 125px;
}

.input-unit--right {
    margin-left: 10px;
    font-weight: 400;
}

.selected-dates {
    display: flex;
    margin-bottom: 25px;
    align-items: baseline;
    font-size: $size-13;
}

.selected-dates__title {
    text-transform: uppercase;
    margin-right: 10px;
}

.selected-dates__item {
    display: inline-flex;
    margin: 2px;
    padding: 6px 2px 6px 8px;
    width: 105px;
    background-color: $c-secondary--light;
    border-radius: $border-radius;

    span {
        margin-left: 3px;
        cursor: pointer;
    }
}

.selected-dates__date {
    width: 75px;
    white-space: nowrap;
}

.input-with-suggestions {
    position: relative;
    width: 100%;
}

.dropdown-suggestions {
    position: absolute;
    width: 100%;
    max-height: 220px;
    overflow-y: auto;
    padding: 8px 0;
    margin: 10px 0;
    z-index: $z-index-2;
    background-color: $bg-light;
    border-radius: $border-radius;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.25);

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.options-param {
    display: flex;
    align-items: center;

    .label-name {
        margin-right: 10px;
    }

    .DayPickerInput input {
        width: 125px;
        padding: 10px 15px;
        background: none;
    }
}

.form--with-legend {
    position: relative;
    padding-top: 10px;
}

.form-legend--required-field {
    position: absolute;
    top: -10px;
    right: 0;

    color: $f-medium;
}

.required-accent {
    color: $c-error;
    margin: 0 3px;
}

.tbody--message {
    tr {
        height: auto;
        border: none;
    }

    td {
        padding: 0;
        width: 100%;
    }

    .form-message {
        width: 100%;
    }
}

.input-desc--bottom {
    font-size: $f-size--s;
    font-weight: 400;
    color: $f-medium;

    span {
        margin-right: 5px;
    }
}

@include mobile {

    .form--horizontal {
        label {
            flex-direction: column;
        }

        .right-section {
            margin-left: 0;
        }

        .DayPicker {
            margin-top: 15px;
        }
    }
}

.dropdown-suggestions__item {
    padding: 8px 15px;
    font-weight: 400;
    cursor: pointer;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
        background-color: $c-secondary--light;
    }
}

.select-with-button-wrapper {
    display: flex;

    .grow {
        flex-grow: 1;
        padding-right: 15px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;

        .select-wrapper {
            margin-bottom: 10px;
        }
    }
}

.removable-input {
    display: flex;
    position: relative;
    margin-bottom: 5px;

    textarea {
        min-height: 60px;
    }

    .btn {
        padding: 0 5px;
    }

    &.with-icon {
        img {
            position: absolute;
            top: 10px;
            left: 10px;
        }

        textarea {
            padding-left: 40px;
        }
    }
}

.subtitle-modal {
    margin-top: 7px;
    padding: 10px 40px;
    background-color: $c-secondary--light;

    p {
        display: inline-block;
    }
}

.legend-modal {
    display: flex;


    .legend-modal--column {
        width: 50%;
        margin-right: 10px;

        @media screen and (max-width: 500px) {
            width: auto;
        }
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}

.form-group-title {
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid $c-secondary--medium;
    font-size: $size-16;
    font-weight: 500;
}

.input--number {
    width: 42px;
    margin: 0 10px;
    padding: 10px 5px;
    text-align: center;
}

.label-group {
    display: flex;
}

.label-group__long {
    position: relative;
    flex-grow: 1;
}

.label-group__short {
    position: relative;
    width: 100px;
    margin-left: 15px;

}

.field-group-header {
    font-size: 16px;
    padding: 10px 15px;
    font-weight: 500;
    color: $f-dark;
    background-color: #f4f6f9;
}

.font-size-13 {
    font-size: 13px;
}