.side-map-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    margin: 10px 0;

    button {
        font-weight: 500;
        color: $c-primary;
    }
}

.collection-icon {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: -6px;
    border-radius: 50%;
    border: 2px solid #fff;

    svg {
        max-height: 100%;
    }
}

.collection-icon:not(:first-child) {
    margin-left: -6px;
}

.map-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
}