.dropzone-root {
    padding: 10px;
    margin: -10px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px dashed $c-secondary--dark;
    border-radius: 4px;
    cursor: pointer;
    height: 110px;
    width: 137px;
}

.dropzone__info-banner {
    .info-banner {
        background-color: #dfebff;
        height: 48px;
        margin-top: unset;
        padding: 16px 20px;
    }
}

.dropzone__icon {
    margin-bottom: 7px;
}

.dropzone__text {
    color: $c-primary;
    max-width: 89%;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.18;
    text-align: center;

    &--gray {
        color: $f-medium;
    }
}

.dropzone--hover {
    box-shadow: 0 0 0 3px #d6e2f7;
}

.dropzone--horizontal{
    width: 100%;
    height: auto;
    padding: 14px 30px;
    flex-direction: row;
    justify-content: flex-start;

    .dropzone__icon{
        margin-right: 10px;
        margin-bottom: 0;
    }
}

.dropzone--error {
    border-color: $c-error;
}

.uploaded-file{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: $c-secondary--light;
    border-radius: $border-radius;

    .file-name--is-loading{
        color: $f-medium;
    }

    .progress{
        position:absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.uploaded-file--error{
    border: 1px solid $c-error;
}


.uploaded-file__icon{
    height: 24px;
    width: 24px;
    background: url("../../../resource/img/icons/icon_paperclip.svg") no-repeat;
}

.uploaded-file__name{
    width: 100%;
    margin-left: 20px;
    font-size: $f-size--s;
    line-height: 24px;
}

.uploaded-file__alternative_text{
    color: $c-primary;
    font-size: 12px;

    .update-action__link{
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
    }

    span + span{
        margin-left: 5px;
    }
}

.uploaded-file__delete{
    height: 24px;
    width: 16px;
    margin-left: 20px;
    cursor: pointer;

    svg{
        margin: 4px 0;
    }
}

.uploaded-file__moved-section{
    margin-top: 6px;
}
