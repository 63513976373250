.has-bottom-action-buttons{
    position: relative;
    padding-bottom: 80px;
}

.bottom-action-buttons{
    position: fixed;
    z-index: $z-index-1;
    bottom: 0;
    width: 100%;
    background-color: $bg-light;
    border-top: 1px solid $c-secondary--medium;

    .content--not-centered{
        padding-top: 0;
    }
}

.bottom-action-buttons__content{
    padding: 20px 0;

    &.bottom-right{
        display: flex;
        justify-content: flex-end;
    }
}

.bottom-left-save{

}

.bottom-action-buttons--full-width{
    left: 0;
}

.bottom-action-message{
    display: inline-block;
    margin: 10px 20px;

    &--sent{
        color: $c-success;
    }

    &--error{
        color: $c-error;
    }
}