.tooltip-trigger-icon {
  position: relative;
  display: inline-flex;
  margin-left: 10px;
  max-height: 70%;
  max-width: 50px;
  cursor: pointer;

  &:hover,
  &:focus {
    .tooltip-desc {
      visibility: visible;
    }
  }
}

.tooltip-desc {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  width: 300px;
  max-width: 100vw;
  padding: 10px;
  visibility: hidden;
  transition-delay: 100ms;
  cursor: auto;
  box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.2);
  border-radius: $border-radius;
  font-weight: 400;
  font-size: $f-size--s;
  line-height: 1.17;
  z-index: $z-index-2;

  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $c-primary;
  }

}

.tooltip-desc--dark {
  background-color: $c-primary;
  color: $f-light;

  &:before {
    border-bottom-color: $c-primary;

  }
}

@include until($tablet) {
  .tooltip-desc {
    right: -10px;
    left: unset;
    transform: none;

    &:before {
      right: 10px;
      left: unset;
      transform: none;
    }
  }
}
