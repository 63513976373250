.generate-qrs-btn {
   width: 450px;
}

.qr-codes-generation--addresses {
  width: 100%;
  max-width: 870px;
  padding: 6px 15px 0;
  background-color: #eef1f5;

  &--row {
    padding: 9px 16px 0 0;
    height: 34px;
    font-size: 14px;
    font-family: Rubik, sans-serif;
    line-height: 1.21;
    width: 100%;
    border-bottom: 1px solid #d9dfe9;
    display: flex;
    justify-content: space-between;

    &:last-child {
      border: none;
    }

    svg {
      margin-top: -5px;
      cursor: pointer;
    }
  }
}
