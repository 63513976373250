.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $c-secondary--dark;
    height: 40px;
    width: 40px;

    .cls-2 {
        stroke: $c-primary;
    }

    &--squared {
        border-radius: 4px;
    }

    &--small {
        height: 32px;
        width: 32px;
    }
}

.icon--white{
    .svg-icon{
        fill: #fff;
    }
}

.icon--danger{
    .svg-icon{
        fill: $c-error;
    }
}