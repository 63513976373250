.modal-size-xs {
    width: $modal-size-sx;
}

.modal-size-s {
    width: $modal-size-s;
}

.modal-size-m {
    width: $modal-size-m;
}

.modal-size-l {
    width: $modal-size-l;
}

.modal-size-xs, .modal-size-s, .modal-size-m, .modal-size-l {
    max-width: 100vw;
}

.lock-scroll {
    overflow: hidden !important;
}

.c-modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-modal;
    transform: translateZ(0);
    background-color: rgba(#0b1a33, 0.45);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-modal {
    position: relative;
    max-height: 96vh;
    margin: 2vh 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
}

.c-modal--overflow-visible{
    overflow-y: unset;
    overflow-x: unset;
}

.c-modal__header {
    max-height: 70px;
    padding: 0 40px;
    position: sticky;
    top: 0;
    z-index: $z-index-1;
    background-color: #fff;
}

.c-modal__header--with-bg {
    background-color: $c-secondary--light;
}

.c-modal__title {
    font-size: $size-22;
    font-weight: 500;
}

.c-modal__close_wrapper {
    position: relative;
    width: 100%;
    height: 37px;
}

.c-modal__close {
    display: inline-flex;
    position: absolute;
    top: 2px;
    right: 0;
    outline: none;
    padding: 5px;
    cursor: pointer;
    z-index: $z-index-2;
}

.c-modal__body__base {
    min-height: 100px;
    padding: 30px 40px;
    background-color: #fff;
}

.c-modal__body {
    @extend .c-modal__body__base;

    label {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .DayPicker {
        margin-top: 15px;
    }
}

.c-modal__footer {
    padding: 15px 30px;
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: $c-secondary--light;

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.c-modal__footer--apart {
    justify-content: space-between;
}

.c-modal__footer--left {
    justify-content: flex-start;
}

.c-modal__footer--right {
    justify-content: flex-end;
}

.modal--no-header{
    padding-top: 10px;
}

.modal-no-header{
    padding-top: 10px;
}

.confirmation-alert {
    min-height: 50px;
    padding-left: 50px;
    padding-top: 5px;
    background-image: url("../../../resource/img/icons/icon_alert_circle.png");
    background-repeat: no-repeat;
    background-position: left top;
}

.c-modal--scroll-locked{
    overflow: hidden;
}
