.tiles-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 310px);
    grid-gap: 15px;
    padding-bottom: 30px;
}

.tiles-item {
    position: relative;
    width: 310px;
    height: 180px;
    border: 1px solid $c-secondary--dark;
    border-radius: $border-radius;

    .dropdown {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }

    &--clickable {
        &:hover,
        &:focus {
            box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.2);
            border-color: $c-cta;
        }
    }

    &--deactivated {
        color: $f-medium;
        border-color: $c-secondary--medium;
    }
}

.tiles-item__content {
    display: inline-block;
    width: 100%;
    padding: 30px 25px 20px;
    height: inherit;
}

.tiles-item__name {
    margin-bottom: 10px;
    overflow-y: auto;
    font-weight: 500;
    font-size: $size-16;
    line-height: 1.5;
    white-space: pre-line;
}

.tiles-item__name--2-lines {
    max-height: 35%;
}

.tiles-item__name--3-lines {
    max-height: 55%;
}

.tiles-item__name--4-lines {
    max-height: 75%;
}

.tiles-item__actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 25%;
}

.icon--menu {
    padding: 2px;
    border-radius: $border-radius;
    border: 1px solid transparent;
    line-height: 0;

    &:hover,
    &:focus {
        border-color: $c-cta;
    }
}

.tiles-item--add-new {
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    strong {
        display: block;
        color: $c-primary;
    }
}

.tiles-item__activate-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    color: $c-primary;
    font-weight: 500;

    svg {
        margin-left: 5px;
    }
}

.code-box {
    display: inline-block;
    font-weight: 500;
    margin-top: 5px;
    background-color: $c-secondary--light;
}

.code-box--small {
    padding: 4px 12px;
}

.code-box--large {
    padding: 12px 35px;
}