.qr-code-documents-empty {
  margin-top: 100px;
  font-size: 14px;
  font-weight: 500;
  color: $c-secondary--dark;
  text-align: center;
}
.qr-code-documents-list {
  max-width: 660px;

  table {
    width: 100%;
    max-width: 580px;
    min-width: 0;
  }

  .notification-dot {
    opacity: 0.0;
    display: inline-block;
    margin-right: 6px;
    margin-top: -2px;
    position: absolute;
    top: 6px;
    left: -14px;
  }


  .main-info {
    &--ready {
      font-weight: 500;
      position: relative;

      .notification-dot {
        opacity: 1.0;
      }
    }

    &--removed {
      color: $f-medium;
    }
  }

  .info-column {
    display: flex;
    flex-flow: wrap;
  }

  .action-column {
    display: flex;
    justify-content: flex-end;
  }

  .details-column {
    display: flex;
    justify-content: center;
    padding: 0;

    svg {
      cursor: pointer;
    }
  }

  .status-info {
    color: $f-medium;
  }

  .progress-bar {
    padding: 10px;
    background-color: #f4f6f9;
    border-radius: 4px;
  }

  &__group-item {
    border-bottom: 1px solid $c-secondary--light;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
