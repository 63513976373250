.indeterminate-progress-bar {
  height: 4px;
  width: 100%;
  margin: 0;
  background-color: #ccd5e3;
  display: -webkit-flex;
  display: flex;

  &:before {
    height: 4px;
    width: 100%;
    margin: 0;
    background-color: #347af6;
    content: '';
    -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @-webkit-keyframes running-progress {
    0% { margin-left: 0; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0; }
    100% { margin-left: 100%; margin-right: 0; }
  }
  @keyframes running-progress {
    0% { margin-left: 0; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0; }
    100% { margin-left: 100%; margin-right: 0; }
  }
}

