.report-details__header {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $c-secondary--medium;
    min-height: $page-subheader-height;
    margin-bottom: 18px;

    .report-details__go-back-link {
        margin-right: 15px;
    }

    .report-details__icon {
        display: none;
    }

    @media screen and (min-width: 480px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (min-width: 900px) {

        .report-details__go-back-link {
            display: none;
        }

        .report-details__icon {
            display: block;
        }
    }

    @media screen and (min-width: 1300px) {
        padding: 15px 25px 15px 70px;
    }
}

.report-details__icon {
    margin-right: 15px;
}

.report-details__close {
    cursor: pointer;
    margin-left: auto;
    margin-right: 20px;
    @media screen and (min-width: 900px) {
        display: none;
    }

    & > svg {
        width: 40px;
        height: 40px;
    }
}

.report-details__content {
    max-width: 740px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 60px;

    @media screen and (min-width: 480px) {
        margin-left: 30px;
        margin-right: 30px;
    }

    @media screen and (min-width: 900px) {
        margin-bottom: 40px;
    }

    @media screen and (min-width: 1300px) {
        margin-top: 40px;
        margin-left: 110px;
        margin-bottom: 40px;
    }
}

.report-details__section {
    margin-bottom: 40px;
}

.report-details__row {
    position: relative;
    margin-bottom: 10px;
}

.report-details__row--select {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include from($tablet){
        flex-direction: row;
    }
}

.report-details__label--select {
    @media screen and (min-width: $tablet-brakepoint) {
        padding-top: 10px;
        flex: 0 0 160px;
    }
}

.report-details__row--info {
    display: flex;
    flex-direction: column;

    @include from($tablet){
        flex-direction: row;
    }
}

.report-details__row--map {
    margin-bottom: 15px;
    @media screen and (min-width: $tablet-brakepoint) {
        margin-bottom: 20px;
    }
}

.report-details__row--bins {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 10px;
    margin: 40px 0;
}

.report-details__label {
    font-size: 13px;
    font-weight: 500;
    display: block;

    @include from($tablet){
        font-size: 14px;
        flex: 0 0 180px;
    }
}

.report-details__label--bottom-8 {
    margin-bottom: 8px;
}

.report-details__text {
    font-size: 13px;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: 14px;
    }
}

.report-details__subheader {
  font-size: 16px;
  margin-bottom: 12px;
  @media screen and (min-width: $tablet-brakepoint) {
    font-size: 18px;
  }
}

.report-details__field-input {
    width: 250px;

    & input {
        margin: 0;
        height: auto;
    }
}

.report-details__confirmation {
    font-size: $f-size--s;
    font-weight: 500;
    margin: 8px 0 6px;
}

.report-details__confirmation--sent {
    color: $f-medium;
}

.report-details__confirmation--pending {
    color: $c-primary;
}

.report-details__gallery {
    margin-bottom: 50px;
    & .image-modal:last-of-type > .report-details__field-img-container {
        margin-right: 0;
    }
}

.report-details__field-img-container {
    font-size: 0;
    margin-right: 12px;
    position: relative;
    transition: box-shadow .2s ease-in;

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.3);

        .report-details__field-img-icon {
            opacity: 1;
            cursor: pointer;
        }

        .report-details__field-img-overlay {
            cursor: pointer;
            opacity: .4;
        }
    }
}

.report-details__field-img-icon {
    opacity: 0;
    position: absolute;
    bottom: 10px;
    right: 8px;
    transition: opacity .2s ease-in;
    z-index: 1;
}

.report-details__field-img-overlay {
    background-color: $c-primary;
    border-radius: 4px;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-in;
    width: 100%;
}

.report-details__field-img {
    border-radius: 4px;
    width: 176px;
    height: 140px;
    object-fit: cover;

    &:hover {
        cursor: pointer;
    }
}

.report-details__form {
    position: relative;
    max-width: 740px;
}

.report-details__text-box {
    position: relative;
}
