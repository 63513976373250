.day-picker-wrapper{
  max-width: 1200px;
  margin: 0 auto;
}

.DayPicker{
  width: 100%;
  font-size: unset;
}

.DayPicker-wrapper{
  padding: 0;
}

.DayPicker-NavBar{

}

.DayPicker-NavButton{
  top: 0;
}

.DayPicker-NavButton--prev{
  background-image: url(../../../resource/img/icons/icon_chevron_left.svg);
  left: 0;
  right: unset;
  margin-right: 0;
}

.DayPicker-NavButton--next{
  background-image: url(../../../resource/img/icons/icon_chevron_right.svg);
  right: 0;
}

.DayPicker-Months{
  min-height: 270px;
}

.DayPicker-Month{
  margin: 0 20px 30px;
  border-collapse: unset;
  border-spacing: 10px;
}

.c-modal .DayPicker-Month{
  margin: 0;
}

.DayPicker-Body{
  font-weight: 400;
}

.DayPicker-Weekdays{
}

.DayPicker-Weekday{
  color: $f-dark;
  font-weight: 500;
}

.DayPicker-Weekdays, .DayPicker-Body{
  font-size: $f-size--s;
}

.DayPicker-Caption{
  margin-bottom: 0;
}

.DayPicker-Caption > div{
  font-size: $size-14;
  text-align: center;
  text-transform: capitalize;
}

.DayPicker-Day{
  width: 24px;
  height: 24px;
  border-radius: $border-radius;
  padding: 0;
}

.DayPicker-Day--today{
  font-weight: 400;
  color: unset;
}

//selected for publication
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  background-color: $bg-light;
  border: 2px solid $c-cta;
  color: $f-dark;
}

// hover on: selected for publication
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
  background-color: $bg-light;
  border: 2px solid $c-cta;
}

// hover
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{
  background-color: unset;

  border: 2px solid $c-cta;
}

.DayPicker-Day--municipalWasteNew:not([aria-disabled="true"]){
  background-color: $bg-light;
  border: 2px solid $c-cta;
  color: $f-dark;
}

.DayPicker-Day--municipalWasteRemoved:not([aria-disabled="true"]){
  background-color: $c-secondary--medium;
  color: $f-dark;

  &:hover{
    background-color: $c-secondary--medium;
    color: $f-dark;
  }
}

.DayPicker-Day--municipalWastePublished:not([aria-disabled="true"]){
  background-color: $c-cta;
  color: $f-light;
}

// published selected for removal (DayPicker-Day--municipalWasteRemoved)
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--municipalWasteRemoved{
  background-color: $c-secondary--medium;
  border: none;
  color: $f-dark;
}

// published hover (DayPicker-Day--municipalWastePublished)
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--municipalWastePublished:hover{
  background-color: $c-cta;
  color: $f-light;
}

@include until($tablet){
  .DayPicker-Month{
    margin-bottom: 15px;
  }
}

// Day Picker Input

.DayPickerInput{

  input {
    max-width: 150px;
    padding-right: 40px;

    background-image: url("../../../resource/img/icons/icon_calendar.svg");
    background-repeat: no-repeat;
    background-position: 115px center;
  }

  input:active {
    box-shadow: none;
  }

  .DayPicker-wrapper{
    padding-top: 15px;
  }

  .DayPicker-Month{
    min-height: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .DayPicker-NavButton--prev{
    background-image: url(../../../resource/img/icons/icon_chevron_left.svg);
    left: 10px;
    top: 15px;
    right: unset;
    margin-right: 0;
  }

  .DayPicker-NavButton--next{
    background-image: url(../../../resource/img/icons/icon_chevron_right.svg);
    right: 10px;
    top: 15px;
  }

  .DayPickerInput-OverlayWrapper{
    margin: 0;
  }

}

.DayPickerInput-OverlayWrapper{
  margin-top: 10px;
  box-shadow: $box-shadow;
}