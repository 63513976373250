$selection-color: #f4f6f9;

.react-select-container {
    &.rejected {
        .react-select__control {
            border-color: $c-error;
        }
    }
}

.select-wrapper {
    width: 100%;
    max-width: 100%;

    .select-all-button {
        padding: 10px 10px;
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
        color: $f-dark;
        cursor: pointer;

        &:hover {
            background-color: $selection-color;
        }
    }

    &.rejected {
        .react-select-container {
            .react-select__control {
                border-color: $c-error;
            }
        }
    }

    .react-select__value-container {
        font-weight: 400;
        min-height: 2.6rem;

        //clear default input styles only for react-select
        input {
            width: 0;
            height: 20px;
            box-shadow: none;
        }
    }

    .react-select__placeholder {
        font-weight: 400;
        color: $c-secondary--dark;
    }

    .react-select__menu {
        z-index: $z-index-3;
        font-weight: 400;
        box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.25);
        border-radius: $border-radius;
    }


    .react-select__option.react-select__option--is-focused {
        background-color: unset;
    }

    .react-select__option,
    .react-select__option.react-select__option--is-focused {
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $selection-color;
        }
    }

    .react-select__option--is-selected,
    .react-select__option--is-selected.react-select__option--is-focused {
        color: $f-dark;
        background-color: $selection-color;
        font-weight: 500;
    }

    .react-select__multi-value {
        background-color: $selection-color;

        &__label {
            color: $f-dark;
            font-size: 14px;
        }

        &__remove {
            color: $c-secondary--dark;
            cursor: default;

            &:hover {
                color: $c-primary;
                background-color: $selection-color;
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .react-select__indicators {
        align-items: baseline;
        padding-top: 2px;
        cursor: default;

        .react-select__clear-indicator {
            color: $c-primary;
        }
    }

    .react-select__value-container--has-value {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .react-select--is-searchable {
        .react-select__control {
            cursor: text;
        }
    }

    .react-select__new-vale-label {
        color: #347af6;
        font-weight: 500;
    }
}

.select-wrapper--bare {
    .react-select__control {
        min-height: 20px;
        background-color: transparent;
        border: none;
    }

    .react-select__value-container {
        min-height: 20px;
        padding: 0 10px;
    }

    .react-select__indicator {
        padding: 0;
    }

    .react-select__single-value {
        color: $f-dark;
    }
}

.select-wrapper--with-icon {
    .react-select__value-container {
        position: relative;
        padding-left: 40px;
        height: 100%;
        display: block;
    }

    .react-select__single-value {
        max-width: calc(100% - 40px);
    }
}

.select-wrapper--map-marker-icon {
    .react-select__value-container {
        &:before {
            content: url("../../../resource/img/icons/icon_map_marker_blue.svg");
            position: absolute;
            width: 24px;
            height: 24px;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);

        }
    }
}


.select-with-icons {
    .select-with-icons__single-value {
        display: flex;
        align-items: center;
    }

    .select-with-icons__option {
        display: flex;
        padding-left: 18px;
        width: 100%;
        align-items: center;
    }

    .select-with-icons__option-disabled {
        color: #aaaaaa;
        cursor: not-allowed;
    }

    .select-with-icons__icon {
        height: 18px;
        width: 18px;
        margin: 0 5px 0 0;
    }
}
