.notifications-3__confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 100px);
    text-align: center;
}

.notifications-3__confirmation-header {
    margin: 20px 0 30px;
}