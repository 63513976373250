.sidebar {
    position: fixed;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(to bottom, #173870, #112953);
    transition: 500ms ease;
    z-index: $z-index-5;

    a,
    .sidebar__item-name {
        color: #fff;

        &:focus,
        &:hover {
            color: #fff;
        }
    }

}

.sidebar,
.sidebar__item,
.module-name {
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: width, min-width, max-width;
    transition-duration: 100ms;
    max-width: $sidebar-width;
    min-width: $sidebar-width;
}

.sidebar--collapsed {
    .sidebar-expand-icon {
        transform: rotate(180deg);
    }
}

.sidebar--collapsed {
    max-width: $sidebar--collapsed-width;
    min-width: $sidebar--collapsed-width;

    .sidebar__submenu {
        display: none;
    }

    .module-name{
        padding-left: 70px;
    }

    .sidebar__link-icon{
        margin-right: 30px;
    }

}

.sidebar-nav-wrapper {
    overflow: auto;
    height: inherit;
    padding-bottom: 60px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

}

.sidebar-trigger {
    display: none;
}

.module-name {
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 15px 20px;
    font-size: $size-16;
    background-color: $c-primary--dark;
    border-bottom: 1px solid #1021409e;
}

.sidebar__item {

}

.sidebar__item--active {
    position: relative;
    background-color: $c-primary--dark;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        border-radius: 0 8px 8px 0;
        background-color: $c-cta;
        z-index: $z-index-1;
    }

    .sidebar__link--has-children-expanded {

        &:after {
            transform: rotate(135deg);
        }
    }
}

.sidebar__link {
    margin: 10px 0;
    padding: 15px 20px;
    cursor: pointer;

    &:hover {
        background-color: $c-primary--dark;
    }

    &--active {
        background-color: $c-primary--dark;
    }
}

.sidebar__link-icon {
    width: 24px;
    margin-right: 15px;
}

.sidebar__link--has-children {
    position: relative;
    padding-right: 45px;

    &:after {
        content: '';
        display: block;
        width: 0.625em;
        height: 0.625em;
        margin-top: -0.4375em;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: center;
        transition: 300ms ease;
        pointer-events: none;

        border: 3px solid $c-secondary--light;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
    }
}

.sidebar__submenu {

}

.sidebar__submenu-item {
    display: block;
    padding: 10px 20px 10px 60px;

    &--active,
    &:hover,
    &:active {
        background-color: $c-primary--darkest;
    }

    &--active {
        cursor: default;
    }
}

.sidebar__control {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-top: 2px solid $c-primary--darkest;
    background-color: #112953;
    z-index: $z-index-2;

    button {
        padding: 15px;
        outline: none;
    }

    .sidebar-expand-icon {
        transition: 200ms ease;
    }
}

@include until($desktop) {
    .sidebar {
        height: 100vh;
        position: absolute;
        left: -260px;

        &.is-active {
            left: 0;
            z-index: $z-index-4;
            transition: 500ms ease-in-out;
        }
    }

    .sidebar-nav-wrapper {
        overflow: scroll;
    }

    .sidebar--visible {
        position: fixed;
        left: 0;
    }

    .sidebar-trigger {
        display: block;
        margin-left: 0;
        width: $row-height--mobile;
        height: $row-height--mobile;
        background-color: $c-primary;
        border: none;
        flex-shrink: 0;

        &:active,
        &:focus,
        &:hover {
            background-color: $c-primary;
        }

        span {
            color: $f-light;
            height: 2px;
            width: 20px;
        }
    }

}