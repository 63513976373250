//Container with background styles

.container--with-bg{
    background-color: $c-secondary--medium;
}


// Container without sidebar styles

.container--no-sidebar {
    .main-area {
        padding-left: 0;
    }

    .sidebar-trigger{
        display: none;
    }
}

// Container with sidebar styles

.container--with-sidebar {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    .admin-navbar {
        width: calc(100vw - #{$sidebar-width});
    }

    .main-area {
        padding-left: $sidebar-width;
    }
}

.main-area {
    flex-grow: 1;
    min-height: 100vh;
    max-width: 100vw;
    padding-left: $sidebar-width;
}

.main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: $row-height;
    padding-bottom: 20px;
}

.main-content--table{
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$row-height + 20px});
}

.main-content--table__top{
    flex: 0 0 auto;
}

.main-content--table__bottom{
    flex: 1 1 auto;
    overflow-y: auto;
}

.with-sidebar--collapsed {
    .admin-navbar {
        width: calc(100vw - #{$sidebar--collapsed-width});
    }

    .main-area {
        padding-left: $sidebar--collapsed-width;
    }

}

@include from($desktop) {

    .with-sidebar--expanded{
        .bottom-action-buttons{
            width: calc(100% - #{$sidebar-width});
        }
    }
}

@include until($desktop) {

    .container--with-sidebar {
        .admin-navbar {
            width: 100vw;
        }

        .main-area {
            padding-left: 0;
        }
    }


    .main-content{
        padding-top: $row-height--mobile;
    }

    .main-content--table{
        padding-bottom: 30px;
    }
}