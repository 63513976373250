.subcategory{
    margin-bottom: 30px;
}

.subcategory-list-item-status{
    width: 420px;
}

.service-card{
    .options-wrapper--horizontal{
        max-width: 350px
    }

    .media:last-child{
        margin-bottom: 60px;
    }

    .form-legend--required-field{
        top: 10px;
    }
}

.media-content--steps{
    max-width: 100%;

    @media screen and (min-width: $desktop-brakepoint) {
        max-width: calc(100% - 70px);
    }
}

.triple-buttons-wrapper{
    display: flex;

    .btn:not(:last-child), .with-modal:not(:last-child){
        margin-right: 15px;
    }

    .far-right{
        margin-left: auto;
    }

    .far-left{
        margin-right: auto;
    }

    @media screen and (max-width: 620px) {
        flex-direction: column;
        .btn, .btn:not(:last-child){
            margin: 5px 0 0;
        }

        .with-modal{
            width: 100%;

            .btn{
                width: 100%;
            }
        }
    }
}