.title-with-bottom-divider {
    display: flex;
    position: relative;
    cursor: default;
    justify-content: space-between;
}

.title-with-bottom-divider__title {
    font-size: $size-16;
    font-weight: 500;
    line-height: 10px;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: $size-18;
        margin-bottom: 20px;
    }
}

.title-with-bottom-divider__additional-text {
    color: #183970;
    font-weight: 500;
    font-size: $size-14;
    line-height: 10px;
    @media screen and (min-width: $tablet-brakepoint) {
        margin-bottom: 20px;
    }
}

.title-with-bottom-divider__divider {
    border-bottom: 1px solid $c-secondary--medium;
    height: 0;
    margin: 0 0 5px 0;
}