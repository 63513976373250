//pass requirements

.pass-requirements {
  margin-bottom: 20px;
  font-size: $size-12;
  line-height: 1.8;

  li {
    position: relative;
    padding-left: 20px;

    &.neutral:before,
    &.accepted:before,
    &.rejected:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &.neutral{
      color: $c-secondary--dark;
      &:before {
        background-color: $c-secondary--light;
      }
    }

    &.accepted:before {
      background-color: $c-success;
      background: url("../../../../resource/img/icons/icon_check_small.svg") center/cover no-repeat;
    }

    &.rejected:before {
      background-color: $c-error;
      background: url("../../../../resource/img/icons/icon_alert_small.svg") center/cover no-repeat;
    }
  }
}

input[type='password'] {
  padding-right: 50px;
}

.show-pass {
  position: absolute;
  height: 20px;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.form-controls {
  display: flex;

  .btn {
    width: 50%;
    min-width: auto;
    margin: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}