.image-modal-trigger{
    display: inline-block;
}

.c-modal--image {
    background: unset;
    box-shadow: none;
    height: auto;
    padding: 17px;
    @media screen and (min-width: 500px) {
        max-width: 80vw;
    }
}

.c-modal__body--image {
    & > img {
        max-height: 90vh;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
        display: block;
        margin: 0 auto;
    }
}

.c-modal__close--image{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    border: none;
}