.title-with-divider {
    display: flex;
    position: relative;
    cursor: default;
}

.title-with-divider__title {
    font-size: $size-16;
    font-weight: 500;
    line-height: 10px;
    margin-bottom: 15px;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: $size-18;
        margin-bottom: 20px;
    }
}

.title-with-divider__divider {
    border-bottom: 1px solid $c-secondary--medium;
    height: 0;
    flex-grow: 1;
    margin: 10px 0 0 10px;
}