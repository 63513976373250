.sll__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    height: 70px;
    font-size: $size-13;
    padding: 10px 10px 10px 30px;
    position: relative;
    border-bottom: 1px solid $c-secondary--medium;

    &:last-child {
        border-bottom: 1px solid $c-secondary--medium;
    }

    &--unread {
        font-weight: 500;
        color: $f-dark;

        .sll__list-item__icon-wrapper {
            background-color: $c-cta;
            border: none;
        }

        .icon-contour {
            fill: $bg-light;
        }
    }

    &--selected {
        background-color: $c-secondary--light;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            border-left: 4px solid $c-cta;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            width: 20px;
            height: 70px;
        }
    }
}

.sll__list-item__info-wrapper {
    display: flex;
    align-items: center;
    max-width: 80%;
    overflow: hidden;
}

.sll__list-item__icon-wrapper {
    position: relative;
    flex-shrink: 0;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $c-secondary--dark;
    background-color: $bg-light;
}

.sll__list-item__icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sll__list-item__notification {
    position: absolute;
    right: -4px;
    bottom: -8px;
}

.sll__list-item__information {
    max-width: calc(100% - 50px);
    margin-right: 10px;
}

.sll__list-item__title {
    color: $f-dark;
}

.sll__list-item__title,
.sll__list-item__date,
.sll__list-item__address {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}