.drag-and-drop__column {
    width: 400px;
}

.drag-and-drop__content {
    margin-top: 5px;
    display: flex;
    column-gap: 5px;
    width: 400px;
}

.drag-and-drop__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border: none;
    border-radius: 4px;
    background: $bg-gray;
}

.drag-and-drop__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    padding: 10px 0 10px 10px;
    border-radius: 6px;
    background: $bg-gray;

    span {
        flex-basis: 90%;
    }
}

.drag-and-drop__current {
    color: $f-light;
    background: $c-primary--dark;
}