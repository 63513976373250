.row .tabs{

  .tabs-list{
    border-bottom-color: $c-secondary--medium;
  }

  li{
    display: flex;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
    color: $f-medium;
    border-bottom: 2px solid transparent;


    .tab-item, a{
      display: block;
      position: relative;
      padding: 11px 13px;
      height: auto;
      border: none;
      border-radius: $border-radius;
      color: inherit;
    }

    &.is-active{
        color: $f-dark;
        font-weight: 500;
        border-bottom-color: $c-primary;
    }

    &:not(.is-active){
      &:hover,
      &:focus{
         border-bottom-color: $c-secondary--medium;
      }
    }
  }


  .tab-item--removable{
    padding: 0 8px;

    &:hover,
    &:focus{
      .tab-item--with-icon{
        background-color: $c-secondary--light;
      }

      .tab-item__delete{
        display: block;
      }
    }
  }

}

.tab-item--with-icon{
  svg{
    margin-right: 6px;
  }
}

.tab-item__delete{
  position: absolute;
  display: none;
  top: -7px;
  right: -7px;
  width: 18px;
  height: 18px;
  background-color: $c-secondary--dark;
  border-radius: 50%;

  svg{
    margin: 0;
  }

  &:hover,
  &:focus{
    background-color: $c-error;
  }

}