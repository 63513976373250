$sidebar-width: 260px;
$sidebar--collapsed-width: 60px;
$top-nav-height: 70px;
$page-subheader-height: 78px;
$row-height: 70px;
$row-height--mobile: 60px;

$border-radius: 4px;
$box-shadow: 0 4px 10px 0 rgba(24, 57, 112, 0.3);

$modal-size-sx: 480px;
$modal-size-s: 560px;
$modal-size-m: 610px;
$modal-size-l: 720px;

$content-width--xs: 540px;
$content-width--s: 650px;
$content-width--m: 950px;
$content-width--l: 1366px;