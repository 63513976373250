.unordered-list {
    padding-left: 15px;
    list-style: disc;
}

.instruction-list {
    margin-bottom: 15px;

    h3 {
        font-weight: 500;
        margin-bottom: 5px;
    }

    ul{
        list-style: disc;
        margin-left: 0;
        padding-left: 15px;
    }
}