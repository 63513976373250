.drop-to-right {
    right: 0;
    left: unset !important;
}

.is-spaced--bottom-50 {
    margin-bottom: 50px;
}

.is-spaced--bottom-40 {
    margin-bottom: 40px;
}

.is-spaced--bottom-30 {
    margin-bottom: 30px;
}

.is-spaced--bottom-20 {
    margin-bottom: 20px;
}

.is-spaced--bottom-10 {
    margin-bottom: 10px;
}

.is-spaced--top-10 {
  margin-top: 10px;
}

.is-spaced--top-20 {
    margin-top: 20px;
}

.is-spaced--top-30 {
    margin-top: 30px;
}

.is-spaced--top-40 {
    margin-top: 40px;
}

.is-spaced--top-50 {
    margin-top: 50px;
}

.is-spaced--right-10 {
    margin-right: 10px;
}

.is-spaced--right-20 {
    margin-right: 20px;
}

.is-spaced--right-40 {
    margin-right: 40px;
}

.is-spaced--right-65 {
    margin-right: 65px;
}

.is-spaced--left-10 {
    margin-left: 10px;
}

.is-spaced--left-30 {
  margin-left: 30px;
}

.is-spaced--left-20 {
    margin-left: 20px;
}

.is-spaced--left-5 {
    margin-left: 5px;
}

.is-spaced--left-2 {
    margin-left: 2px;
}

.is-v-centered {
    display: flex;
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
    display: flex;
    //align-items: center;
    justify-content: space-between;
}

.is-relative {
    position: relative;
}

.is-rounded {
    border-radius: 50%;
}

.is-uppercase {
    text-transform: uppercase;
}

.is-capitalized {
    text-transform: capitalize;
}

.is-bold {
    font-weight: 500;
}

.is-underlined {
    text-decoration: underline;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.mw-100 {
    max-width: 100%;
}

.overflow--hidden {
    overflow: hidden;
}

.icon-wrapper {
    margin: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-wrapper--with-bg {
    border-radius: 50%;
    background-color: $c-secondary--light;
}

.clr {
    clear: both;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

// gutters

.gutters-1 {
    padding: 0.25rem;
}

.gutters-2 {
    padding: 0.5rem;
}

.badge {
    padding: 2px 5px;
    background-color: $c-secondary--dark;
    color: $f-light;
    border-radius: $border-radius;
    text-transform: uppercase;
    font-size: $size-11;
}

.prevent-long-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.no-wrap {
    white-space: nowrap;
}

.notice--small {
    margin: 8px;
    font-size: $f-size--s;
    color: $f-medium;
}

.with-gray-bg {
    background-color: $c-secondary--light;
    padding: 20px;
}

.is-clickable {
  cursor: pointer;
}
