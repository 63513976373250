.module__category{
  display: block;
  height: 100%;
  padding: 30px;
  border: 1px solid $c-secondary--dark;
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus{
    box-shadow: $box-shadow;
  }
}

.module__category-bg{
  .icon-bg{
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    opacity: 0.2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.module__category--management{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_calendar.svg");
    bottom: -16px;
    right: -26px;
  }
}

.module__category--messages{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_send.svg");
    bottom: -16px;
    right: -16px;
  }
}

.module__category--reports{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_message.svg");
    bottom: -16px;
    right: -20px;
  }
}

.module__category--areas{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_pin.svg");
    bottom: -16px;
    right: -26px;
  }
}

.module__category--areas{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_pin.svg");
    bottom: -16px;
    right: -26px;
  }
}

.module__category--companies{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_car.svg");
    bottom: -16px;
    right: -26px;
  }
}

.module__category--payments{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_wallet.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--inspections{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_ekostraznik_inspections.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--reports{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_ekostraznik_reports.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--eko-companies{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_ekostraznik_comapnies.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--inventories{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_ekostraznik_inventories.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--addresses{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_buildings.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--communal_documents{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_download_gray.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--messages_blisko{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_message.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--receiver-group{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_info_big.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--address-template{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_folder.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category--sender-details{
  .icon-bg{
    background-image: url("../../../resource/img/icons/sidebar/icon_card.svg");
    bottom: -16px;
    right: -13px;
  }
}

.module__category-desc{
  position: relative;
  z-index: $z-index-1;

  .title{
    margin-bottom: 10px;
    line-height: 1.3;
  }
  .subtitle{
    color: $f-dark;
  }
}
