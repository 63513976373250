.message-sending-1__content {
    margin-top: 45px;
    max-width: 870px;
    @media screen and (min-width: $desktop-brakepoint) {
        margin-left: 115px;
        margin-right: 60px;
    }
}

.message-sending-1__row {
    display: flex;
    position: relative;
}

.message-sending-1__row--flex {
    flex-direction: column;
    @media screen and (min-width: $tablet-brakepoint) {
        flex-direction: row;
    }
}

.message-sending-1__section {
    margin-top: 20px;
}

.message-sending-1__subsection {
    margin-bottom: 20px;
}

.message-sending-1__subtitle {
    position: relative;
}

.message-sending-1__subtitle--bottom-15 {
    margin-bottom: 15px;
}

.step-count-wrap{
    display: none;

    @media screen and (min-width: $desktop-brakepoint) {
        display: block;
    }
}

.step-count {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid $c-secondary--medium;
    border-radius: 4px;
    height: 52px;
    width: 54px;
}

.message-sending-1__inputs-group {
    display: flex;
    flex-direction: column;

    > .message-sending-1__input-wrap {
        margin-right: 20px;
        .message-sending-1__input-wrap {
            margin-bottom: unset;
        }
    }

    @media screen and (min-width: $tablet-brakepoint) {
        flex-direction: row;
    }
}

.message-sending-1__input-wrap {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    width: 100%;
}

.message-sending-1__input-wrap--with-icon {
    display: flex;
    align-items: center;
}

.message-sending-1__input-wrap--date {
    margin-right: 30px;
}

.DayPickerInput-OverlayWrapper {
    z-index: 200;
}

.message-sending-1__input-wrap--select {
    margin-right: 20px;
}

.message-sending-1__input-wrap--date-time {
    max-width: unset;
    width: 100%;
    @media screen and (min-width: $tablet-brakepoint) {
        max-width: 160px;
    }
}

.message-sending-1__icon {
    margin-right: 10px;
}

.message-sending-1__label {
    color: $c-primary;
    font-size: 13px;
    font-weight: 500;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: 14px;
    }
}

.message-sending-1__label--bottom-8 {
    margin-bottom: 8px;
    display: block;
}

input.message-sending-1__input {
    width: 100%;
}

.message-sending-1__dropzone {
    margin-bottom: 30px;
}

.message-sending-1__box {
    border-radius: 4px;
    max-height: 600px;
    overflow: auto;
    background-color: $bg-gray;
    padding: 15px;
}

.message-sending-1__box--list {
    max-height: 360px;
    padding: 10px 17px;
}

.message-sending-1__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0 6px 0;

    &:not(:last-child){
        border-bottom: 1px solid $c-secondary--medium;
    }

    .delete-trigger{
        display: block;
        height: 24px;
        cursor: pointer;
    }
}

.message-sending-1__toggle {
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;

    align-items: center;
    background-color: $bg-gray;
    border-radius: 4px;
    display: flex;
    font-weight: 500;
    height: 56px;
    max-width: 440px;
    padding-left: 16px;
    padding-right: 16px;

    & input {
        height: 42px;
        margin: 0;
    }

    @media screen and (min-width: $tablet-brakepoint) {
        width: auto;
    }
}

.message-sending-1__toggle--bottom-5 {
    margin-bottom: 5px;
}

.message-sending-1__map {
    font-size: 0;
}

.message-sending-1__address {
    margin-bottom: 10px;
}

.message-sending-1__footer {
    min-height: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 0;

    @media screen and (min-width: $tablet-brakepoint) {
        flex-direction: row;
        align-items: center;
        padding: 0;
    }

    &::before {
        content: '';
        height: 1px;
        width: 120vw;
        position: absolute;
        top: 0;
        left: -114px;
        background-color: $c-secondary--medium;
    }
}

.message-sending-1__dash {
    margin: 10px 5px;
}

.message-sending-1__button {
    min-width: 160px;
    margin-bottom: 20px;
    @media screen and (min-width: $tablet-brakepoint) {
        margin-top: 20px;
        margin-bottom: 0;
    }
}