.f-12 {
    font-size: $size-12;
}

.f-13 {
    font-size: $size-13;
}

.f-14 {
    font-size: $size-14;
}

.f-15 {
    font-size: $size-15;
}

.f-18 {
    font-size: $size-18;
}

strong{
    font-weight: 500;
}

.text-center{
    text-align: center;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

a{
    color: unset;

    &:hover,
    &:focus{
        color: unset;
    }

    &.btn{
        font-weight: 500;
    }
}

.text-separator{
    margin: 0 10px;
    color: $f-medium;
}