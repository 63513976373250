.empty-list-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px); // reduce of header height
}

.empty-list-page__content {
    text-align: center;
    max-width: 350px;
    & > img {
        margin-bottom: 10px;
    }
    & > p {
        font-weight: 500;
        line-height: 1.2;
        color: $c-secondary--dark;
        font-size: 14px;
    }
    & > a {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        margin-top: 30px;
    }
}