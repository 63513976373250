button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
    opacity: 0.4;

    &:hover,
    &:focus {
        box-shadow: none;
    }
}

.unstyled-btn {
    display: inline-flex;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.label-icon-btn {
    display: inline-flex;
    margin-left: 5px;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //min-width: 210px;
    padding: 0 20px;
    height: 2.6rem;
    border-radius: $border-radius;
    border: none;
    font-size: $f-size--m;
    font-weight: 600;
    cursor: pointer;
    color: inherit;
    transition: 300ms ease;
    background-color: transparent;
    vertical-align: top;
}

.btn--primary {
    background: linear-gradient(106deg, #347af6, #1659ce);
    color: $f-light;

    &:hover, &:focus {
        color: $f-light;
        box-shadow: 0 4px 10px 0 rgba(24, 57, 112, 0.3);
    }
}

.btn--danger{
    background: linear-gradient(106deg, $c-error, $c-error-gradient);
    color: $f-light;

    &:hover, &:focus {
        color: $f-light;
        box-shadow: 0 4px 10px 0 rgba(24, 57, 112, 0.3);
    }
}

.btn__icon-wrapper--left {
    position: relative;
    padding-left: 34px;

    .btn__icon {
        left: 0;
    }
}

.btn__icon-wrapper--right {
    position: relative;
    padding-right: 34px;

    .btn__icon {
        right: 0;
    }
}

.btn__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;

    svg {
        width: 100%;
        height: 100%;
    }

}

.btn--outlined {
    background-color: $bg-light;
    border: 1px solid $c-secondary--dark;
    color: $c-primary;

    &:hover, &:focus {
        color: $c-primary;
        box-shadow: 0 4px 10px 0 rgba(24, 57, 112, 0.3);
    }
}

.btn--icon {
    width: 42px;
    min-width: auto;
    padding: 0;
}

.btn__icon--single {
    width: 24px;
    height: 24px;

    svg {
        width: 100%;
        height: 100%;
    }
}

.btn--narrow {
    min-width: auto;
    height: auto;
    padding: 5px;
}

.btn--xsmall {
    min-width: 128px;
}

.btn--small {
    min-width: 140px;
}

.btn--medium {
    min-width: 160px;
}

.btn--big {
    min-width: 240px;
}

.btn--full-width {
    width: 100%;
}

.btn__arrow {
    height: 8px;
    width: 8px;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 8px;
}

.btn--short{
    height: 1.5rem;
    font-size: $f-size--s;
}

.action-btn{
    margin-left: 15px;
}