.notifications-2 {

    .info-banner{
        background-color: transparent;
        padding-left: 0;
        margin-bottom: 40px;
    }
}

.notifications-2__row {
    display: flex;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.notifications-2__row--flex {
    flex-direction: column;
    @media screen and (min-width: $tablet-brakepoint) {
        flex-direction: row;
    }
}

.notifications-2__content {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
    padding-bottom: 165px;
    max-width: 950px;
    @media screen and (min-width: $tablet-brakepoint) {
        padding-bottom: 100px;
    }
}

.notifications-2__label {
    color: $f-dark;
    font-size: 13px;
    font-weight: 500;
    display: block;
    padding-top: 10px;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: 14px;
        flex: 0 0 160px;
        margin-bottom: 0;
    }
}

.notifications-2__box {
    border-radius: 4px;
    max-height: 600px;
    overflow: auto;
    background-color: $bg-gray;
    padding: 15px;
    @media screen and (min-width: $tablet-brakepoint) {
        width: calc(100% - 160px);
    }

    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal inside;
    }
}

.notifications-2__box--list {
    padding: 10px 17px;
}

.notifications-2__list-item {
    padding: 7px 0 6px 0;
    border-bottom: 1px solid $c-secondary--medium;
    @extend .notifications-2__thumb-title;
}

.notifications-2__thumb-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (min-width: $tablet-brakepoint) {
        width: calc(100% - 160px);
    }
}

.notifications-2__thumb-box {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 300px;
    padding: 2.5px 50px 2.5px 0;
    @media screen and (min-width: $desktop-brakepoint) {
        width: 50%;
    }
}

.notifications-2__thumb {
    border-radius: 4px;
    margin-right: 7px;
}

.notifications-2__thumb-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notifications-2__btn-footer-group {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: $phone-brakepoint) {
        flex-direction: row;
    }
}
