.blank-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.blank-page__content{
    text-align: center;
}

.blank-page__text{
    font-weight: 500;
    color: $c-secondary--dark;
}