.tile-selection {
    margin: 90px 0 20px;
    padding: 0 15px;
}

.module-wrapper{
    border-radius: $border-radius;
    border: 1px solid transparent;
    transition: 100ms ease-in;

    &:hover, &:focus{
        border: 1px solid $c-cta;
        cursor: pointer;
    }
}

.module {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 200px;

    overflow: hidden;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.2);



    img {
        width: auto;
        height: 50px;
    }
}

.module__name{
    .title{
        line-height: 1.3;
    }
    .subtitle{
        color: $f-medium;
    }
}

.tile-selection__owner{
    margin-top: -40px;
}

.tile-selection__owner-name{
    font-weight: 500;
    margin-bottom: 5px;
    font-size: $f-size--xl;
}

.module__icon{
    position: absolute;
    bottom: -15px;
    right: -25px;
    width: 120px;
    height: 130px;
}

.module__icon--waste-management{
    background: url("../../../resource/img/icons/modules/waste_management.svg");
}

.module__icon--services{
    background: url("../../../resource/img/icons/modules/services.svg");
}

.module__icon--ekostraznik{
    background: url("../../../resource/img/icons/modules/ekostraznik.svg");
}

.module__icon--blisko{
    background: url("../../../resource/img/icons/modules/blisko.svg");
}