.schedule-year-wrapper{
  direction: rtl;
}

.schedule-menu{

}

.schedule-year{
  font-weight: 500;
  font-size: 38px;
  margin-right: 20px;
  color: $c-secondary--medium;
  cursor: pointer;
}

.schedule-year--selected{
  color: $f-dark;
}

.row--municipal-waste{
  margin: 25px 0;
}

.tabs-wrapper{
  position: relative;
  flex-grow: 1;
  overflow: auto;

  .tabs{
    margin: 0;
    font-size: $f-size--m;
  }

  &.with-icon--left{
    padding-left: 75px;
  }

  &.with-icon--right{
    padding-right: 75px;
  }

  &--select{
    overflow: unset;
  }
}

.add-new-tab{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: $z-index-1;
  padding: 10px;
}

.municipal-waste-bins--tabs{
  display: flex;
}

.municipal-waste-bins--select{
  display: none;
}

@include until($desktop){
  .row--municipal-waste{
    flex-direction: row;
  }

  .schedule-year-wrapper{
    max-width: 80%;
    width: auto;
  }

  .schedule-year{
    font-size: $size-26;
  }

  .schedule-menu{
    text-align: right;
    width: 10%;
  }

  .municipal-waste-bins--tabs{
    display: none;
  }

  .municipal-waste-bins--select{
    display: block;
  }

}