.accordion__section{
    border-radius: $border-radius;
    border: 1px solid transparent;
    transition: 200ms ease-in-out;


    &:not(:last-child){
        margin-bottom: 5px;
    }

    &.active{
        border-color: $c-secondary--dark;


        .accordion__header{
            border-radius: $border-radius;
        }

        .accordion__trigger_icon{
            transform: rotate(180deg);
        }

        .accordion__content{
            height: auto;
            max-height: 325px;
            overflow: auto;
        }
    }
}

.accordion__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 13px;
    height: 48px;
    background-color: $c-secondary--light;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.accordion__icon,
.accordion__action_icon,
.accordion__trigger_icon{
    display: inline-flex;
}

.accordion__trigger_icon,
.accordion__action_icon{
    cursor: pointer;
}


.accordion__icon{
    margin-right: 10px;
    max-width: 24px;
    max-height: 24px;
}

.accordion__action_icon{
    margin-right: 10px;

    span{
        height: 24px;
    }

    &:last-of-type{
        position: relative;
        margin-right: 20px;

        &:after{
            content: "";
            position: absolute;
            right: -10px;
            top: -8px;
            width: 1px;
            height: 40px;
            background-color: $c-secondary--medium;
        }
    }
}

.accordion__trigger_icon{
    transition: 200ms ease-in-out;
    padding: 10px;
}

.accordion__title-wrapper{
    max-width: calc(100% - 150px);
}

.accordion__title{
    font-weight: 500;
}

.accordion__content{
    transition: 200ms ease-in-out;
    height: 0;
    overflow: hidden;

    label{
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
}

.accordion__content-spacing{
    padding: 20px;
}

.accordion__content--wysiwyg{

    .accordion__content-spacing{
        padding: 0;
    }

    .RichEditor-root{
        border: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}