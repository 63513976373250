.sll__blankpage {
    display: none;
    justify-content: center;
    align-items: center;
    //height: calc(100vh - 200px);
    flex-grow: 1;
    @media screen and (min-width: 900px) {
        display: flex;
    }
}

.sll__blankpage__content {
    text-align: center;
}

.sll__blankpage__text {
    font-weight: 500;
    color: $c-secondary--dark;
}