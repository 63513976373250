.sll__content {
    display: flex;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    border-top: 1px solid $c-secondary--medium;
}

.sll__details {
    max-height: 100%;
    overflow: auto;

    position: absolute;
    top: $row-height--mobile;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-2;
    background-color: $bg-light;

    @media screen and (min-width: 900px) {
        position: static;
        flex-grow: 1;
    }
}