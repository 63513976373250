.loader-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 20%;
  width: 100%;
}

.loader--custom{
  text-align: center;
}

.spinner {
  width: 60px;
  height: 60px;

  position: relative;
  margin: 40px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $c-cta;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;

  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

.button-loader {
    padding-right: 15px;

    .spinner {
        width: 24px;
        height: 24px;

        position: relative;
        margin: 0;
    }

    .double-bounce1, .double-bounce2 {
        background-color: #fff;
    }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
    }
}