.rc-time-picker-panel-input-wrap{
    display: none;
}

.rc-time-picker-panel-select{
    width: 62px;
}

.rc-time-picker-panel-inner{
    margin-top: 3.1rem;
    border: none;
    box-shadow: $box-shadow;
}

.rc-time-picker-panel-narrow{
    max-width: unset;
}

.rc-time-picker-panel{
    width: auto;
}

li.rc-time-picker-panel-select-option-selected{
    font-weight: 500;
}

.rc-time-picker-input{
    color: $f-dark;
    max-width: 150px;
    padding-right: 40px;

    background-image: url("../../../resource/img/icons/icon_clock.svg");
    background-repeat: no-repeat;
    background-position: 115px center;
}