.user-reply {
    display: flex;
    border: 1px solid $c-secondary--medium;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 10px;
    height: 60px;

    &--show {
        height: auto;
    }
}

.user-reply__icon {
    margin-right: 10px;
}

.user-reply__message {
    flex-grow: 1;
    color: $c-primary;
    font-size: $size-14;
    padding: 13px 10px 0 0;
    overflow: hidden;
}

.user-reply__button {
    align-self: flex-start;
    background: unset;
    border: none;
    outline: none;
    padding: 14px 8px;
}

.user-reply__arrow {
    width: 8px;
    height: 8px;
    border: 3px solid #173870;
    border-right: none;
    border-bottom: none;
    border-radius: 2px;
    transform: rotate(-135deg);
    transition: transform .2s ease-in;

    &--hide {
        transform: rotate(45deg);
    }
}
