.toast{
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(200px);
    bottom: 0;
    border-radius: $border-radius;
    z-index: $z-index-toast;

    animation-name: slide-up-and-down;
    animation-duration: 6s;
    animation-timing-function: ease;


    &.toast--info{
        border: 4px solid rgba($c-cta, 0.25);

        .toast__content{
            background-color: $c-primary;
        }
    }

    &.toast--error{
        border: 4px solid rgba($c-error, 0.25);

        .toast__content{
            background-color: $c-error;
        }
    }

    @media screen and (min-width: 480px) {
        bottom: 100px;
    }
}

.toast__content{
    min-width: 400px;
    padding: 17px 20px;
    color: $f-light;
    text-align: center;
}

.bottom-action-buttons{
    .temp-toast{
        bottom: 123px;

        @media screen and (min-width: 480px) {
            bottom: 100px;
        }
    }
}

@-webkit-keyframes fadeOut{from{opacity:1}
    to{opacity:0}
}

//animation

@keyframes slide-up-and-down {
    0% {
        transform: translateX(-50%) translateY(200px);
    }

    20% {
        transform: translateX(-50%) translateY(0);
    }

    80% {
        transform: translateX(-50%) translateY(0);
    }

    100% {
        transform: translateX(-50%) translateY(200px);
    }
}
