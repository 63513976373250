.session-time {
    padding: 5px 15px;
    background-color: $c-secondary--light;
    font-size: 0.7em;
}

.admin-navbar {
    position: fixed;
    display: flex;
    width: 100%;
    padding: 10px 30px;
    background-color: $bg-light;
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2);
    z-index: $z-index-4;
}

.admin-navbar__right{
    margin-left: auto;
}

.admin-navbar__navbar-item {
    display: inline-flex;
    padding: 0 15px;
    align-items: center;
}

.admin-navbar__navbar-item--adduser {
    @media screen and (max-width: $tablet-brakepoint) {
        display: none;
    }
}

.admin-navbar__navbar-item--user {
    position: relative;
    font-family: Rubik, sans-serif;
    margin-left: 0;
    outline: none;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;

    @media screen and (min-width: $tablet-brakepoint) {
        margin-left: 10px;
        padding-left: 10px;
        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 49px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $c-secondary--light;
        }
    }
}

.admin-navbar__desktop-name {
    display: none;
    @media screen and (min-width: $tablet-brakepoint) {
        display: block;
        max-width: 300px;
        text-align: left;
    }
}

.admin-navbar__desktop-name-field {
    @media screen and (min-width: $tablet-brakepoint) {
        color: $f-dark;
        font-size: 14px;
    }
}

.admin-navbar__desktop-email-field {
    @media screen and (min-width: $tablet-brakepoint) {
        color: $f-medium;
        font-size: 12px;
    }
}

.admin-navbar__user-icon {
    display: inline-flex;
    align-items: center;
    height: 2.6rem;
    cursor: pointer;
    padding-left: 0;
    padding-right: 20px;
    @media screen and (min-width: $tablet-brakepoint) {
        padding: 0 20px;
    }
}

.dropdown.is-active .admin-navbar__dropdown-menu {
    display: block;
}

.admin-navbar__dropdown-menu {
    display: none;
    position: absolute;
    padding-top: 0;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.25);
    border-radius: 4px;
    top: 115%;
    z-index: 200;
}

.admin-navbar__dropdown-menu--modules {
    left: -60px;
    @media screen and (min-width: $phone-brakepoint) {
        left: 0;
    }
}

.admin-navbar__dropdown-content {
    padding: 8px 0;
}

.admin-navbar__dropdown-item {
    font-size: 14px;
    color: $f-dark;
    padding: 8px 15px;
    text-align: left;
    width: 100%;
    display: block;
    &:hover {
        background-color: whitesmoke;
        color: $f-dark;
    }
}

.dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 13px;
    border-bottom: 2px solid $c-secondary--medium;
    &:hover {
        background-color: unset;
    }
}

.dropdown-title__link{
    flex-shrink: 0;
    width: 37px;
    margin-left: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $f-size--s;
    color: $c-cta;

    &:hover, &:focus{
        text-decoration: underline;
        color: $c-cta;
    }
}

.admin-navbar__dropdown-item--modules-item {
    padding: 15px;
}

.admin-navbar__dropdown-item--mobile-user {
    cursor: default;
    background-color: #eceff4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    text-align: left;
    @media screen and (min-width: $tablet-brakepoint) {
        display: none;
    }
    &:hover {
        background-color: #eceff4;
    }
}

.admin-navbar__dropdown-item--title-divider {
    padding: 6px 0 4px 15px;
    &:hover {
        cursor: default;
        background-color: unset;
    }
    .title-with-divider__title {
        color: $f-dark;
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.admin-navbar__dropdown-item-mobile-user-name {
    color: $f-dark;
    font-size: 15px;
}

.admin-navbar__dropdown-item-mobile-user-email {
    color: $f-medium;
    font-size: 12px;
}

.admin-navbar__dropdown-divider {
    margin: 0;
    height: 0;
    border-bottom: 1px solid #d9dfe9;
}




@include until($desktop){
    .admin-navbar{
        padding: 0;
    }
}

.row-bg {
    background-color: $c-secondary--light;
}
