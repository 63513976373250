.pagination--list{
    width: 100%;
    margin-bottom: -20px;
    border-top: 2px solid #d9dfe9;

    .sll__pagination-wrapper{
        border-top: none;
    }
}

