.header--spaced {
    margin-bottom: 60px;
}

.page-header {
    padding: 23px 30px;
    background-color: $c-secondary--light;

    .title {
        line-height: 1.4;
    }

    .select-wrapper {
        width: 600px;
        max-width: 100%;
    }
}

.page-header--with-back {
    display: flex;

    .title {
        padding-left: 15px;
    }
}

.page-header--no-sidebar {
    padding: 50px 0 40px;
    background-color: transparent;

}

.header--with-select {
    margin-bottom: 23px;
}

.header--basic {
    margin: 40px 0;

    .title,
    .title:not(:last-child) {
        margin-bottom: 8px;
        line-height: 1.5;
    }
}

.header__search {
    width: 360px;
    max-width: 100%;
    flex-shrink: 0;
}

.row {
    height: $row-height;
    display: flex;
    align-items: center;

    button:not(:last-child) {
        margin-right: 10px;
    }
}

.row__right {
    flex-shrink: 0;
}

.row__left {
    flex-shrink: 1;

    @include from($desktop) {
        margin-right: 20px;
    }

    &.scrollable {
        overflow-x: auto;
    }

    &.wrapable {
        flex-wrap: wrap;
    }
}

.row--options {
    height: 60px;
    margin: 10px 0;

    .options-item {
        z-index: 200;

        &:not(:last-child) {
            margin-right: 30px;
        }
    }

    .options-item--range {
        display: flex;
    }

    &.flexible-height-wrap {
        min-height: 60px;
        height: auto;

        input {
            margin: 5px 0;
        }
    }
}

.row--separated {
    justify-content: space-between;
}

.row--end {
    justify-content: flex-end;
}

.row--bg {
    background-color: $c-secondary--light;
}

.row--bottom {
    margin-top: 20px;
}

.header--centered {
    display: inline-block;
    position: relative;
    padding: 0 50px;
    margin: 35px 0 25px;
    text-align: center;
    border-top: 1px solid $c-secondary--medium;

    h3 {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        padding: 5px 30px;
        background-color: $bg-light;
    }
}

.header--underlined {
    padding: 15px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid $c-secondary--light;

    .title {
        font-size: $f-size--l;
    }

    .header__action-btns {
        max-height: $f-size--l;
    }
}

.header-icon {
    width: 56px;
    height: 56px;
    padding: 11px 5px;
    border-radius: 28px;
    border: solid 1px #d9dfe9;
}

.signed-out-page__confirmation_header {
    margin: 40px 0;
    font-size: $f-size--l;
    font-weight: 500;
    text-align: center;
}

@include until($desktop) {

    .btn.header__main-cta-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        margin: 0;
        z-index: $z-index-3;
        border-radius: 0;
    }
}

@include until($desktop) {

    .row {
        height: $row-height--mobile;
    }

    .row__left,
    .row__right {
        width: 100%;
    }

    .row--options {
        height: auto;
        flex-wrap: wrap;

        button, input, .select-wrapper {
            margin: 5px 0;
        }
    }

    .row--separated {
        flex-direction: column;
    }

    .page-header {
        padding: 15px 20px;
    }

    .header--with-select {
        margin-bottom: 10px;
    }

    .header--centered {
        margin: 50px 0 0;

        h3 {
            padding: 10px;
            font-size: $size-13;
        }
    }
}
