.table-wrapper {
    width: 100%;
}

table {
    width: 1200px;
    min-width: 100%;
}

thead {
    display: block;
    position: sticky;
    top: 0;
    z-index: $z-index-1;

    tr {
        background-color: $c-secondary--light;
        border-radius: $border-radius;
    }

    th:first-child {
        border-radius: $border-radius 0 0 $border-radius;
    }

    th:last-child {
        border-radius: 0 $border-radius $border-radius 0;
    }
}

tbody {
    display: block;
    width: inherit;
    min-width: inherit;

    tr {
        border-bottom: 1px solid $c-secondary--light;
    }
}

tr {
    position: relative;
    display: flex;
}

th {
    font-weight: 500;
    white-space: nowrap;
}

th, td {
    padding: 5px 18px;
    display: inline-flex;
    align-items: center;
    text-align: left;
}
.td-action-buttons {
    width: auto;
    flex-shrink: 0;
    justify-content: flex-end;
    margin-left: auto;
    padding: 0 5px;

    &:after {
        content: '';
        position: absolute;
        left: 5px;
        height: 65%;
        border-left: 1px solid $c-secondary--medium;
    }

    button, a {
        margin: 0 8px;
        padding: 0;
    }

    span, a {
        display: inline-flex;
    }
}

.sticky--right {
    position: sticky;
    right: 0;
}

.table--single-line {
    tr {
        height: 50px;
    }

    tr:hover, .td-action-buttons:hover {
        background-color: $c-secondary--light;
    }

    .table-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .preceding-title {
        margin-right: 23px;
        position: relative;

        &:after {
            content: "-";
            position: absolute;
            right: -15px;
        }
    }
}

.th-action-btn {
    position: relative;
    display: inline-flex;
    margin-left: 10px;
    max-height: 50%;
    max-width: 50px;
    cursor: pointer;

    &:hover,
    &:focus {
        .th-hidden-info {
            visibility: visible;
        }
    }
}

.th-hidden-info {
    position: absolute;
    top: 35px;
    left: 50%;
    visibility: hidden;
    transition-delay: 100ms;
    transform: translateX(-50%);
    width: 300px;
    padding: 15px;
    cursor: auto;
    background-color: $bg-light;
    border-radius: $border-radius;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.2);

    &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $bg-light;
    }
}

.th-hidden-info__row {
    display: flex;
    align-items: center;
    font-size: $size-13;
    font-weight: 400;
    white-space: initial;

    svg, img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 10px;
    }

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.single-date:not(:last-child) {
    margin-right: 15px;
}

.t-checkbox {
    flex-shrink: 0;
    width: 40px;
    height: 18px;
}

.t-width-1 {
    width: 8.33%;
}

.t-width-2 {
    width: 16.66%;
}

.t-width-3 {
    width: 25%;
}

.t-width-4 {
    width: 33.33%;
}

.t-width-5 {
    width: 41.66%;
}

.t-width-6 {
    width: 50%;
}

.t-width-7 {
    width: 58.33%;
}

.t-width-8 {
    width: 66.66%;
}

.t-width-9 {
    width: 75%;
}

.t-width-10 {
    width: 83.33%;
}

.t-width-11 {
    width: 91.66%;;
}

.prepared-for-publication {
    margin: 4px 0;
    border-radius: $border-radius;
    border: 1px dashed $c-cta;
    border-right: none;
    background-color: $bg-light-blue;

    .table-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .td-action-buttons {
        background-color: $bg-light-blue;
        border-right: 1px dashed $c-primary;

        &:before {
            background: linear-gradient(to left, $bg-light-blue, transparent);
        }
    }

    & tr:first-child {
        .td-action-buttons {

            &:before {
                border-radius: 0 4px 0 0;
            }
        }
    }

    & tr:last-child {
        .td-action-buttons {

            &:before {
                border-radius: 0 0 4px 0;
            }
        }
    }
}

.table-text-with-badge {
    width: 90%;
}

.selected-row {
    background-color: rgba($c-secondary--light, .5);

    .td-action-buttons {
        background-color: rgba($c-secondary--light, .5);

        &:before {
            background: linear-gradient(to left, rgba($c-secondary--light, .5), transparent);
        }
    }
}

.td--column {
    flex-direction: column;
    align-items: flex-start;
}

.secondary-text {
    color: $f-medium;
}

.secondary-text--under-ellipsis {
    margin-bottom: -5px;
}

.status-icon {
    margin: 0 6px 3px 0;
}

.table-status {
    height: 18px;
    margin-right: 4px;
}

.empty-element {
    text-align: center;
    padding: 22px 15px;
    color: $c-secondary--dark;
    font-weight: 500;
}

//.table-wrapper--flexible {
//    overflow: auto;
//}

.table--flexible {
    width: 100%;
    min-width: 800px;

    .table-text-content {
        max-width: 500px;
    }
}

.data--flexible {
    flex-grow: 1;
    flex-shrink: 1;
}

.data--fixed {
    flex-shrink: 0;
}

.tr--clickable {
    margin: 0 2px;
    cursor: pointer;

    &:hover, &:focus {
        box-shadow: 0 2px 5px 0 rgba(24, 57, 112, 0.3);
    }
}

.tr__alert-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    border-radius: 50%;
    border: 1px solid $c-secondary--dark;
}

.tr__alert-icon--error {
    border-color: $c-error;
}

.td--space-between {
    display: flex;
    justify-content: space-between;
}

.table-select{
    position: relative;
    padding-right: 20px;
    color: $c-cta;
    text-transform: uppercase;
    font-size: $f-size--s;
    font-weight: 500;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -3px;
        display: block;
        width: 20px;
        height: 20px;
        background: url('../../../resource/img/icons/icon_chevron_down_cta.svg');

    }
}
