.sll__list-wrapper {
    position: relative;
    border-right: 1px solid $c-secondary--medium;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.sll__list-wrapper--with-filters {
    padding-top: 40px;
}

.sll__list-wrapper--with-pagination {
    padding-bottom: 70px;
}

.sll__list {
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: auto;
    flex-shrink: 0;

    @media screen and (min-width: 900px) {
        width: 340px;
    }
}

.sll__filters {
    position: absolute;
    top: 0;
    height: 40px;
    width: 100%;
    padding: 10px 10px 10px 30px;
    background-color: $c-secondary--light;
    border-bottom: 1px solid $c-secondary--medium;

    strong {
        color: $f-dark;
    }

    .select-wrapper {
        display: inline-block;
        width: 170px;
    }
}

.sll__pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-top: 1px solid $c-secondary--medium;
    padding: 15px 15px 15px 30px;

    span, input {
        margin: 0 4px;
    }

    .sll__pagination-counter {
        font-size: $f-size--s;
    }
}

.sll__pagination {
    position: relative;
    display: flex;
    align-items: center;

    .sll_pagination__page_number_input {
        width: 40px;
        height: 40px;
        padding: 10px;
        text-align: center;
    }

    &--prev,
    &--next {
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    &--prev {
        left: 0;
        background: url("../../../../../resource/img/icons/icon_chevron_left.svg") no-repeat center;
    }

    &--next {
        right: 0;
        background: url("../../../../../resource/img/icons/icon_chevron_right.svg") no-repeat center;
    }

    .sll__pagination__info {
        padding: 0 30px;
    }
}