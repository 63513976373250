.wysiwyg-wrapper{
    margin-bottom: 15px;
}

.RichEditor-editor {
    cursor: text;
    font-size: 16px;
    resize: vertical;
}

.RichEditor-root {
    background: $bg-light;
    border: 1px solid $c-secondary--dark;
    border-radius: $border-radius;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    padding: 10px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;

    h3, h4{
        font-weight: 500;
    }

    h3{
        font-weight: 500;
        font-size: 24px;
    }

    h4{
        font-size: 20px;
    }
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-controls {
    height: 40px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: $z-index-1;
    overflow: auto;
    padding: 5px 10px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    user-select: none;
    background-color: #fff;
    border-top: 1px solid $c-secondary--dark;
    border-bottom: 1px solid $c-secondary--dark;
}

.RichEditor-styleButton {
    cursor: pointer;
    margin-right: 5px;
    height: 28px;
    padding: 2px;
    display: inline-block;
    border-radius: $border-radius;
    border: 1px solid transparent;

    &:hover, &:focus{
        background-color: $c-secondary--medium;
    }
}

.RichEditor-activeButton {
    background-color: rgba($c-cta, 0.2);
}

.RichEditor-info,
.RichEditor-warning{
    position: relative;
    padding: 0 15px;
    margin-left: 25px;

    &:before{
        content: "";
        position: absolute;
        left: -30px;
        top: 0;
        width: 24px;
        height: 24px;
    }
}

.RichEditor-info{
    border-left: 2px solid $c-cta;

    &:before{
        background: url("../../../resource/img/icons/wysiwyg/icon_info_blue.svg");
    }
}

.RichEditor-warning{
    border-left: 2px solid $c-error;

    &:before{
        background: url("../../../resource/img/icons/wysiwyg/icon_warning_red.svg");
    }
}