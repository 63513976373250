.location-form__row, .location-form__inputs-group {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tablet-brakepoint) {
        flex-direction: row;
    }
}

.location-form__input-wrap {
    cursor: pointer;
    display: block;
    width: 100%;
}

.location-form__input-wrap-width-50 {
    cursor: pointer;
    display: block;
    width: 50%;
}

.location-form__label {
    //color: $c-primary;
    font-size: 13px;
    font-weight: 500;
    @media screen and (min-width: $tablet-brakepoint) {
        font-size: 14px;
    }
}

.location-form__dash {
    margin: 10px 5px;
}
