.dropdown-size-s{
  width: 180px;
}

.dropdown-size-m{
  width: 240px;
}

.dropdown-size-l{
  width: 280px;
}