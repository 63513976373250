.company__defineAreas{
    .media + .media{
        border: none
    }

    .media-content{
        overflow: unset;
    }
}

.define-options{
    margin-top: 15px;

    &:not(:first-child){
        margin-top: 40px;
    }
}

.define-options__list{
    margin: 0 5px 30px;

    li{
        margin-bottom: 10px;
    }
}

.define-options__check-all{
    margin: 13px 0;
}

.tile-item__rating-votes{
    margin-left: 10px;
    color: $f-medium;
}

.tile-item__rating-number{
    margin-right: 10px;
    font-weight: 500;
}