.nests {
    height: 100%;

    &__map {
       height: calc(100% - 60px);
    }

    &__counter-text {
        width: 102px;
        height: 32px;
        font-weight: 500;
        line-height: 1.21;
    }

    &__counter {
        min-width: 50px;
        height: 44px;
        margin: 0 10px 0 0;
        padding: 10px 15px;
        border-radius: 4px;
        background-color: $c-secondary--light;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .mapboxgl-popup {
        max-width: unset !important;

        &-tip {
            display: none;
        }

        &-content {
            padding: 0;
        }
    }
}

.nest-marker {
    position: absolute;
    top: -18px;
    width: 28px;
    height: 37px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIzNyIgdmlld0JveD0iMCAwIDI4IDM3Ij4KICAgIDxkZWZzPgogICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0icHdrOWpxeGhtYSIgeDI9IjEiIHkyPSIxIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2VmMDUwNSIvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNiNzAwMDAiLz4KICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPC9kZWZzPgogICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc4OCAtMjUyKSI+CiAgICAgICAgPGVsbGlwc2UgY3g9IjE0IiBjeT0iMi41IiBmaWxsPSIjMTgzOTcwIiBvcGFjaXR5PSIwLjE1IiByeD0iMTQiIHJ5PSIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDc4OCAyODQpIi8+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzg4IDI1MikiPgogICAgICAgICAgICA8cGF0aCBmaWxsPSJ1cmwoI3B3azlqcXhobWEpIiBkPSJNMTM4IDc0YzAgLjI1NSAwIC41MjItLjAxMy43NzYtLjMxOCA4LjkyNC03LjY4MSAxMy4xNjctMTMuOTg3IDE5LjQ3My01LjY3LTUuNjctMTMuNjY5LTEwLjU1MS0xMy45ODctMTkuNDczQzExMCA3NC41MjIgMTEwIDc0LjI1NSAxMTAgNzRzMC0uNTIyLjAxMy0uNzc2YTE0LjAwOSAxNC4wMDkgMCAwIDEgMjcuOTc1IDBjLjAxMi4yNTQuMDEyLjUyMS4wMTIuNzc2eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMCAtNjApIi8+CiAgICAgICAgICAgIDxjaXJjbGUgY3g9IjYuMzY0IiBjeT0iNi4zNjQiIHI9IjYuMzY0IiBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjYzNiA3LjYzNikiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
    background-size: cover;
    background-position: center;
    cursor: pointer;

    &:hover {
        width: 37px;
        height: 49px;
        top: -24px;
    }
}

.nest-marker-popup {
    position: relative;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    color: $f-dark;
    padding: 12px 15px;
    box-shadow: 0 2px 20px 0 rgba(24, 57, 112, 0.3);
    display: flex;
    align-items: center;
    min-width: 267px;
    justify-content: space-between;

    &__actions {
        height: 24px;
        display: flex;

        svg {
            cursor: pointer;
            margin-left: 14px;
        }
    }
}