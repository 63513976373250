.generate-document {

}

.generate-document__group {
    margin-bottom: 25px;


    .header {
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.generate-document__item {
    display: flex;
    margin-bottom: 10px;

    .checkbox-label__name > * {
        margin-left: 20px;
    }
}